import { GEO_JSON } from '../../../../Constants/MapConstant';
import { captureException, setExtra } from '@sentry/react';

// TODO: Add layer tracker
class UndoRedo {
    constructor(mapObj, AppStore) {
        this.AppStore = AppStore;
        this.mapObj = mapObj;
        this.stack = [];
        this.stackPointer = -1;
    }

    /**
     * Push initial state of map
     */
    init() {
        this.push();
    }

    reset() {
        this.stack = [];
        this.stackPointer = -1;
    }

    /**
     * Push current state of map
     * Call this method on different operations
     */
    push() {
        try {
            const src = this.getLayersGeojson();
            const srcStr = JSON.stringify(src);
            const lastRecordStr = JSON.stringify(this.stack[this.stackPointer]);
            const isLastRecordSame = lastRecordStr ? srcStr == lastRecordStr : '';
            // Check if last element of undo stack and incoming element are not same
            if (src?.length && !isLastRecordSame) {
                const removeItems = this.stack.length - this.stackPointer - 1;
                this.stackPointer++;
                this.stack.splice(this.stackPointer, removeItems, src);
            }
        } catch (err) {
            setExtra('undo_redo_stack', JSON.stringify(this.stack));
            setExtra('Request ID', localStorage.getItem('job_id'));
            captureException(err);
        }
    }

    getLayersGeojson() {
        const layers = this.AppStore.current_layers || [];
        let outputs = [];
        layers.forEach(lId => {
            const layer = this.mapObj.map.getLayerById(lId);
            if (layer) {
                const features = layer.getSource().getFeatures();
                const layerGeojson = GEO_JSON.writeFeatures(features, false);
                const dict = {
                    id: lId,
                    geojson: JSON.parse(layerGeojson)
                };
                outputs.push(dict);
            }
        });
        return outputs;
    }

    setFeatures(layers) {
        if (layers?.length) {
            layers.forEach(l => {
                const prevLayer = this.mapObj.map.getLayerById(l.id);
                prevLayer.getSource().clear();
                prevLayer.getSource().addFeatures(GEO_JSON.readFeatures(l.geojson, false));
            });
            // initTool(localStorage.getItem('current_tool'), localStorage.getItem('selected_layer_id'));
        }
    }

    undo() {
        if (this.stackPointer > 0) {
            this.stackPointer--;
            const layers = this.stack[this.stackPointer];
            this.setFeatures(layers);
        }
    }

    redo() {
        if (this.stackPointer < this.stack.length - 1) {
            this.stackPointer++;
            const layers = this.stack[this.stackPointer];
            this.setFeatures(layers);
        }
    }
}

export default UndoRedo;
