import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

function Header(props) {
    return (
        <div className='header'>
            <div>
                <NavLink to='/'>
                    <img style={{ width: 150 }} src='/img/logo.png' />
                </NavLink>
            </div>
            <div className={'float-right mt'}>
                <span className='mt'>{props.AppStore.user?.email}</span>
                <LogoutOutlined
                    onClick={() => props.AppStore.logoutUser()}
                    style={{ fontSize: '15px', color: 'blue' }}
                    title={'Logout'}
                    className={'ml'}
                />
            </div>
        </div>
    );
}

export default Header;
