import DrawPoint from './Tools/DrawPoint';
import DrawPolyline from './Tools/DrawPolyline';
import DrawPolygon from './Tools/DrawPolygon';
import DrawRectangle from './Tools/DrawRectangle';
import circularHole from './Tools/CircularHole';
import circularFill from './Tools/CircularFill';
import SplitLine from './Tools/SplitLine';
import DrawHoles from './Tools/DrawHoles';
import FillRing from './Tools/FillRing';
import MoveTool from './Tools/MoveTool';
import CutPolygon from './Tools/CutPolygon';
import SelectTool from './Tools/SelectTool';
import ModifyEdge from './Tools/ModifyEdge';
import ReshapePolygon from './Tools/ReshapePolygon';
import RemoveHole from './Tools/RemoveHole';
import CopyTool from './Tools/CopyTool';
import RightClickTool from './Tools/RightClickTool';
import LineSnippingTool from './Tools/LineSnippingTool';
import MeasureTool from './Tools/MeasureTool';

class MapTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.drawTool = new DrawPolygon(mapObj);
        this.drawPolylineTool = new DrawPolyline(mapObj);
        this.drawPointTool = new DrawPoint(mapObj);
        this.drawReactangleTool = new DrawRectangle(mapObj);
        this.splitLineTool = new SplitLine(mapObj);
        this.holeTool = new DrawHoles(mapObj);
        this.fillRingTool = new FillRing(mapObj);
        this.moveTool = new MoveTool(mapObj);
        this.cutTool = new CutPolygon(mapObj);
        this.selectTool = new SelectTool(mapObj);
        this.edgeTool = new ModifyEdge(mapObj);
        this.reshapeTool = new ReshapePolygon(mapObj);
        this.removeHole = new RemoveHole(mapObj);
        this.copyTool = new CopyTool(mapObj);
        this.circularHoleTool = new circularHole(mapObj);
        this.circularFillTool = new circularFill(mapObj);
        this.rightClickTool = new RightClickTool(mapObj);
        this.lineSnippingTool = new LineSnippingTool(mapObj);
        this.measureTool = new MeasureTool(mapObj);
    }

    activateDrawTool(layer_id) {
        this.drawTool.init(layer_id);
    }
    activateDrawPolylineTool(layer_id) {
        this.drawPolylineTool.init(layer_id);
    }
    activateDrawPointTool(layer_id) {
        this.drawPointTool.init(layer_id);
    }
    activateDrawReactangleTool(layer_id) {
        this.drawReactangleTool.init(layer_id);
    }
    activateCircularHoleTool(layer_id) {
        this.circularHoleTool.init(layer_id);
    }
    activateCircularFillTool(layer_id) {
        this.circularFillTool.init(layer_id);
    }
    activateSplitLineTool(layer_id) {
        this.splitLineTool.init(layer_id);
    }
    activateHoleTool(layer_id) {
        this.holeTool.init(layer_id);
    }
    activateFillRingTool(layer_id) {
        this.fillRingTool.init(layer_id);
    }
    activateMoveTool(layer_id) {
        this.moveTool.init(layer_id);
    }

    activateCutTool(layer_id) {
        this.cutTool.init(layer_id);
    }

    activateSelectTool(layer_id) {
        this.selectTool.init(layer_id);
    }

    getSelectTool() {
        return this.selectTool;
    }

    activateEdgeTool(layer_id) {
        this.edgeTool.init(layer_id);
    }

    activateReshapeTool(layer_id) {
        this.reshapeTool.init(layer_id);
    }

    activateRemoveHoleTool(layer_id) {
        this.removeHole.init(layer_id);
    }

    activateCopyTool(layer_id) {
        this.copyTool.init(layer_id);
    }

    activateRightClickTool() {
        this.rightClickTool.init();
    }

    getCopyTool() {
        return this.copyTool;
    }

    activateLineSnippingTool(layer_id) {
        const mode = this.lineSnippingTool.getCurrentMode();
        this.lineSnippingTool.init(layer_id, mode);
    }
    getLineSnippingTool() {
        return this.lineSnippingTool;
    }

    activateMeasureTool(layer_id) {
        this.measureTool.init(layer_id);
    }

    offAllTools() {
        this.drawTool.off();
        this.drawPolylineTool.off();
        this.drawPointTool.off();
        this.drawReactangleTool.off();
        this.circularHoleTool.off();
        this.circularFillTool.off();
        this.splitLineTool.off();
        this.holeTool.off();
        this.fillRingTool.off();
        this.moveTool.off();
        this.cutTool.off();
        this.selectTool.off();
        this.edgeTool.off();
        this.reshapeTool.off();
        this.removeHole.off();
        this.copyTool.off();
        this.lineSnippingTool.off();
        this.measureTool.off();
    }
}

export default MapTool;
