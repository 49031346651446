import { Translate } from 'ol/interaction';
import { undoRedoPush } from '../MapInit';

class MoveTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.translate = null;
        this.hitTolerance = 10;
    }

    init(id) {
        this.off();
        this.layer = this.mapObj.map.getLayerById(id);

        this.translate = new Translate({ layers: [this.layer], hitTolerance: this.hitTolerance });
        this.mapObj.map.addInteraction(this.translate);
        this.mapObj.map.on('pointermove', this.pointerMoveHandler);
        this.translate.on('translateend', undoRedoPush);
    }
    pointerMoveHandler = e => {
        let canvas = document.getElementsByTagName('canvas')[0];
        if (canvas) {
            canvas.style.cursor = '';

            this.mapObj.map.forEachFeatureAtPixel(
                e.pixel,
                (feature, layer) => {
                    if (feature) {
                        canvas.style.cursor = 'move';
                    }
                },
                {
                    layerFilter: layer_candidate => {
                        return this.layer == layer_candidate;
                    },
                    hitTolerance: this.hitTolerance
                }
            );
        }
    };
    off() {
        this.mapObj.map.un('pointermove', this.pointerMoveHandler);
        this.translate && this.mapObj.map.removeInteraction(this.translate);
        this.translate && this.translate.un('translateend', undoRedoPush);
    }
}

export default MoveTool;
