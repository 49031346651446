import React from 'react';
import { Checkbox } from 'antd';
export const RESIDENTIAL_DEFAULT_META_DATA = {};
import { Stroke, Style, Fill } from 'ol/style';
import { undoRedoPush } from '../OlMap/MapInit';
import { toolsObj } from '../OlMap/MapInit';
import { LAYER } from '../../../Constants/MapConstant';

const FEATURE_COLOR = {
    S: '#D80090',
    H: '#1921FF'
};
const LAWN_EDGE_ATTRIBUTE = 'edge_type';
export const layer_colors_list = (
    <div className='lawnx-layer-color-table'>
        <table>
            <tbody>
                <tr>
                    <td style={{ backgroundColor: FEATURE_COLOR['S'] }}>Soft Edge</td>
                    <td style={{ backgroundColor: FEATURE_COLOR['H'] }}>Hard Edge</td>
                </tr>
            </tbody>
        </table>
    </div>
);
export class ResidentialConfig extends React.Component {
    constructor(props) {
        super(props);
        this.AppStore = this.props.AppStore;
        const obj_clone = JSON.parse(JSON.stringify(RESIDENTIAL_DEFAULT_META_DATA));
        this.props.setMetaData(obj_clone);
    }

    parcelCorrectionHandler = () => {
        return (
            <Checkbox
                onChange={e => {
                    if (e.target.checked) {
                        this.props.setMetaData({ tree_overhang: true });
                    } else {
                        const obj_clone = JSON.parse(
                            JSON.stringify({ tree_overhang: false, ...RESIDENTIAL_DEFAULT_META_DATA })
                        );
                        this.props.setMetaData(obj_clone);
                    }
                }}>
                Canopy Layer?
            </Checkbox>
        );
    };
    ratingHandler = (layer, value) => {
        let current_meta_data = this.props.meta_data;
        current_meta_data.ratings[layer] = value;
        const obj_clone = JSON.parse(JSON.stringify(current_meta_data));
        this.props.setMetaData(obj_clone);
    };
    render() {
        const layers = this.AppStore.current_layers;
        if (layers.indexOf(LAYER.CANOPY) > -1) {
            return this.parcelCorrectionHandler();
        } else if (layers.indexOf(LAYER.LAWN_EDGE) > -1) {
            return layer_colors_list;
        }
        return null;
    }
}

export function ResidentialMapComponent() {
    return (
        <select size='2' placeholder='Select Edge Type' id='select-attr'>
            <option value='S'>Soft Edge</option>
            <option value='H'>Hard Edge</option>
        </select>
    );
}

export function ResidentialContextMenuFn(_this, e) {
    const selectTool = toolsObj.getSelectTool();
    const features = selectTool.select ? selectTool.select.getFeatures().getArray() : [];
    if (features.length) {
        const selected_layer = selectTool.layer;
        if (selected_layer && selected_layer.get('id') == LAYER.LAWN_EDGE) {
            let select = document.getElementById('select-attr');
            select.style.display = 'block';
            select.style.top = e.originalEvent.clientY + 'px';
            select.style.left = e.originalEvent.clientX + 5 + 'px';
            select.focus();
            select.onchange = () => {
                features.forEach(feat => {
                    feat.setProperties({ [LAWN_EDGE_ATTRIBUTE]: select.value });
                });
                select.style.display = 'none';
                selectTool.emptyFeatureArray();
                undoRedoPush();
            };
            select.onclick = () => {
                select.style.display = 'none';
                selectTool.emptyFeatureArray();
            };
        }
    } else {
        _this.map.forEachFeatureAtPixel(
            e.pixel,
            function (feature, layer) {
                // Show select
                let select = document.getElementById('select-attr');
                select.style.display = 'block';
                select.style.top = e.originalEvent.clientY + 'px';
                select.style.left = e.originalEvent.clientX + 5 + 'px';
                select.value = feature.getProperties()[LAWN_EDGE_ATTRIBUTE];
                select.focus();
                select.onchange = () => {
                    feature.setProperties({ [LAWN_EDGE_ATTRIBUTE]: select.value });
                    select.style.display = 'none';
                    undoRedoPush();
                };
                select.onclick = () => {
                    select.style.display = 'none';
                };
            },
            {
                layerFilter: layer => {
                    return layer.get('id') == LAYER.LAWN_EDGE;
                },
                hitTolerance: 5
            }
        );
    }
}
export function ResidentialVectorStyleFn(feature, resolution) {
    const edge_type = feature.getProperties()[LAWN_EDGE_ATTRIBUTE];
    return new Style({
        stroke: new Stroke({
            color: FEATURE_COLOR[edge_type] || 'red',
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        })
    });
}
