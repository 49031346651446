import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Button, message, Tag, Divider, Switch, Radio, Space } from 'antd';
import MapComponent from './MapComponent';
import { initOlMap, mapObj, undoRedoObj } from './OlMap/MapInit';
import ToolBox, { ShortcutKeys } from './ToolBox';
import {
    DUMMY_PARCEL,
    SOURCE_TYPE,
    TOOL_TYPE,
    CARTO_ROLES_ENUM,
    LAYER,
    LAYER_DUMMY_JOB
} from '../../Constants/MapConstant';
import { postAPI, interpolate, getAPI } from '../../Utils/ApiCalls';
import { GET_JOB, SUBMIT_JOB, PING, UPDATE_META_DATA, SAVE_JOB } from '../../Constants/Urls';
import { getParameterFromUrl, highlightFeature, highlightFeatures, objectToArray } from '../../Utils/HelperFunctions';
import { isCancel } from 'axios';
import {
    LawnXConfig,
    META_DATA,
    LawnXMapComponent,
    LawnXVectorStyleFn,
    LawnXContextMenuFn
} from './SourceConfig/LawnX';
import { FalconQAConfig, FALCON_QA_DEFAULT_META_DATA } from './SourceConfig/FalconQA';
import {
    FalconConfig,
    // FalconVectorStyleFn,
    FalconMapComponent,
    FALCON_DEFAULT_META_DATA,
    FalconContextMenuFn
} from './SourceConfig/Falcon';
import { LAYER_COLOR, DATA_STATUS } from '../../Constants/MapConstant';
import bbox from '@turf/bbox';
import Header from '../Header';
import Leaderboard from './Leaderboard';
import { NavLink } from 'react-router-dom';
import {
    ResidentialConfig,
    ResidentialContextMenuFn,
    ResidentialMapComponent,
    RESIDENTIAL_DEFAULT_META_DATA
} from './SourceConfig/Residential';
import { ResidentialQAConfig, RESIDENTIAL_QA_DEFAULT_META_DATA } from './SourceConfig/ResidentialQA';
import {
    ResidentialRapidConfig,
    ResidentialRapidContextMenuFn,
    ResidentialRapidMapComponent,
    RESIDENTIAL_RAPID_DEFAULT_META_DATA
} from './SourceConfig/ResidentialRapid';
import { ERROR_INVALID_FEATURES } from '../../Constants/Messages';
const { LAWNX, FALCON, FALCON_QA, RESIDENTIAL_QA, RESIDENTIAL, FMS_QC } = SOURCE_TYPE;
const {
    LAWN,
    CANOPY,
    BEDS,
    LAWN_EDGE,
    SIDEWALK,
    ROADWAYS,
    MULCH_BED,
    GRAVEL_BED,
    DRIVEWAY,
    PARCEL: EDITABLE_PARCEL,
    TREE,
    BUILDING_FOOTPRINT,
    MOWER_60,
    MOWER_48,
    MOWER_36,
    MOWER_21
} = LAYER;

const PING_JOB_TIME = 2000;
const PING_SAVE_JOB_TIME = {
    [LAWNX]: 5000,
    [FALCON]: 60000,
    [FALCON_QA]: 60000,
    [RESIDENTIAL]: 60000,
    [RESIDENTIAL_QA]: 60000,
    [FMS_QC]: 6000
};

const IMAGE_BOUNDS_BASE_LAYER_TYPE = 2;

const OBLIQUE_IMAGE_ORIENATION_MAP = {
    1: 'NORTH',
    2: 'SOUTH',
    3: 'EAST',
    4: 'WEST'
};

@inject('AppStore')
@observer
class AnnotationPage extends React.Component {
    /**
     * @constructor
     * @description AnnotationPage component
     * @param {Object} props Props
     */
    constructor(props) {
        super(props);
        this.AppStore = this.props.AppStore;
        this.state = {
            num_layers: [],
            time: 0,
            selected_layer: null,
            current_job: null,
            is_submit_btn_loading: false,
            on_break: false,
            qc_start_time: null,
            meta_data: {},
            current_images: [],
            current_top_image: '',
            incomplete_layers: [],
            user_ratings: null,
            isRatingModalVisible: false,
            isLeaderBoardVisible: false
        };
        this.demoPage = getParameterFromUrl('demo');
    }

    componentDidMount() {
        initOlMap(this.AppStore);
        if (this.demoPage) {
            this.loadDummyData();
        } else {
            this.listenToJob();
        }
        this.askNotificationPermission();
        // window.addEventListener('keydown', this.handleKeydown, false);
    }

    // handleKeydown = evt => {
    //     if (evt.ctrlKey && evt.which === 83) {
    //         evt.preventDefault();
    //         this.handleSubmitClick();
    //     }
    // };

    askNotificationPermission = () => {
        Notification.requestPermission(function (permission) {
            if (permission !== 'granted') return;
        });
    };

    onChangeMode = e => {
        this.AppStore.setCurrentSourceType(e.target.value);
        this.loadMapData();
    };

    getModes = () => {
        return (
            <div className={'demo-modes'}>
                <Radio.Group onChange={this.onChangeMode} value={this.AppStore.current_source_type}>
                    <Space direction='vertical'>
                        {objectToArray(SOURCE_TYPE).map(mode => (
                            <Radio key={mode.id} value={mode.value}>
                                {mode.value}
                            </Radio>
                        ))}
                    </Space>
                </Radio.Group>
            </div>
        );
    };

    loadDummyData = () => {
        let source_type = 'falcon';
        if (this.AppStore.current_source_type) {
            source_type = this.AppStore.current_source_type;
        }
        this.AppStore.setCurrentSourceType('falcon');
        this.AppStore.setCurrentTool(TOOL_TYPE.PAN);
        this.loadMapData();
    };

    loadMapData = () => {
        const source_type = this.AppStore.current_source_type;
        const source = SOURCE_TYPE_DATA[source_type];
        const jobs = source.getJobs();
        mapObj.removeOutputLayers();
        mapObj.addParcelLayer(DUMMY_PARCEL);
        let selected_layer_id = null;
        jobs.forEach(job => {
            mapObj.addVectorLayer(job.original_json, job.layer_type, source.style);
            selected_layer_id = job.layer_type;
        });

        this.AppStore.setCurrentLayers(source.dummy_layers);
        this.AppStore.setSelectedLayerId(selected_layer_id);
        this.AppStore.setLayersOpacity(this.AppStore.layers_opacity);
        // mapObj.setLayersOpacity(this.AppStore.layers_opacity);

        this.setState({
            current_job: { id: 'test', layers: jobs },
            meta_data: source.meta_data
        });
    };

    getStyle(source_type, layer_type) {
        return SOURCE_TYPE_DATA[source_type]?.style;
        // let style = null;
        // if (source_type == SOURCE_TYPE.LAWNX || layer_type == LAYER_TYPE.LAWNX) {
        //     style = LawnXVectorStyleFn;
        // } else if (
        //     (source_type == SOURCE_TYPE.FALCON ||
        //         source_type == SOURCE_TYPE.FALCON_QA ||
        //         source_type == SOURCE_TYPE.RESIDENTIAL ||
        //         source_type == SOURCE_TYPE.RESIDENTIAL_QA) &&
        //     layer_type == LAYER_TYPE.LAWN_EDGE
        // ) {
        //     style = FalconVectorStyleFn;
        // }

        // return style;
    }

    clearTimers = () => {
        clearTimeout(this.job_ping_timer);
        clearTimeout(this.job_save_timer);
    };

    componentWillUnmount() {
        this.stopTimer();
        this.clearTimers();
        window.removeEventListener('keydown', this.handleKeydown);
    }

    listenToJob = () => {
        if (this.state.current_job) {
            this.startPingTimer();
            return;
        }
        this.job_ping_timer && clearTimeout(this.job_ping_timer);
        this.job_ping_timer = setInterval(() => {
            postAPI(GET_JOB)
                .then(data => {
                    if (data) {
                        if (data.layers.length) {
                            playSound('load');
                            this.showNotification();
                            clearTimeout(this.job_ping_timer);
                            // this.AppStore.setCurrentSourceType(data.source_type);
                            const obj = {
                                current_job: data,
                                qc_start_time: Date.now(),
                                meta_data: this.getSourceDefaultMetaData(),
                                current_images: []
                            };

                            localStorage.setItem('job_id', data.id);

                            if (data.base_layer_type && data.base_layer_type == IMAGE_BOUNDS_BASE_LAYER_TYPE) {
                                mapObj.addImageLayer(data.base_layer, data.image_bounds);
                                obj.current_images.push('ORTHO');
                            }

                            if (data.oblique_images && data.oblique_images.length) {
                                const boundary_layer_bbox = bbox(data.boundary_layer_json);
                                const bounds = {};
                                bounds.left = boundary_layer_bbox[0];
                                bounds.bottom = boundary_layer_bbox[1];
                                bounds.right = boundary_layer_bbox[2];
                                bounds.top = boundary_layer_bbox[3];
                                data.oblique_images.forEach(ob_im => {
                                    const _orientation = OBLIQUE_IMAGE_ORIENATION_MAP[ob_im.orientation];
                                    mapObj.addObliqueImageLayer(ob_im.image_url, _orientation, bounds);
                                    obj.current_images.push(_orientation);
                                });
                            }

                            obj.current_top_image = 'ORTHO';
                            mapObj.addParcelLayer(data.boundary_layer_json);
                            // Hack for setting style based on layer type. Will be changed after API update

                            this.setState({ ...obj }, () => {
                                this.processJobs(data, true);
                            });
                        }
                    }
                })
                .catch(e => {
                    this.setState({ on_break: true });
                    this.clearTimers();
                });
        }, PING_JOB_TIME);
    };

    resetState = () => {
        this.stopSaveTimer();
        this.stopPingTimer();
        this.stopTimer();
        mapObj.removeOutputLayers();
        mapObj.resetMap();
        this.AppStore.setCurrentLayers([]);
        this.AppStore.setSelectedLayerId(null);
        this.AppStore.setLayersOpacity(0);
        undoRedoObj.reset();
        this.setState(
            {
                current_job: null,
                time: 0,
                meta_data: {},
                current_images: [],
                current_top_image: '',
                resetLifeline: !this.state.resetLifeline
            },
            () => {
                if (!this.state.on_break) {
                    this.listenToJob();
                }
            }
        );
    };

    processJobs = (data, withIncomplete = false) => {
        let selected_layer_id = null;
        const layers = [],
            incomplete_jobs = [];
        data.layers.forEach(job => {
            if (job.data_status && job.data_status == DATA_STATUS.PARTIAL) {
                withIncomplete && incomplete_jobs.push(job.layer_type);
            } else {
                mapObj.addVectorLayer(
                    job.original_json,
                    job.layer_type,
                    this.getStyle(data.source_type, job.layer_type)
                );
                layers.push(job.layer_type);
                selected_layer_id = job.layer_type;
            }
        });

        this.AppStore.setCurrentLayers(layers);
        this.AppStore.setSelectedLayerId(selected_layer_id);

        if (this.AppStore.current_source_type === SOURCE_TYPE.LAWNX) {
            mapObj.restrictZoom();
            mapObj.restrictPan();
        }
        if (incomplete_jobs.length) {
            this.setState({ time: data.time_allotted_in_seconds, incomplete_jobs }, () => {
                this.startPartialJobTimer();
            });
        } else {
            this.startTimer(data.time_allotted_in_seconds);
            this.startPingTimer();
            this.startSaveTimer();
        }
    };

    startPartialJobTimer = () => {
        const messageFn = message.loading('Partial data recieved, looking for layers. Please do not submit', 0);
        this.job_ping_timer = setInterval(() => {
            let incomplete_jobs = this.state.incomplete_jobs;
            postAPI(GET_JOB)
                .then(data => {
                    if (data && data.layers.length) {
                        data.layers.forEach(job => {
                            const index = incomplete_jobs.indexOf(job.layer_type);
                            if (index > -1 && job.data_status == DATA_STATUS.COMPLETE) {
                                incomplete_jobs.splice(index, 1);
                            }
                        });

                        this.setState({ incomplete_jobs }, () => {
                            if (incomplete_jobs.length == 0) {
                                clearTimeout(this.job_ping_timer);
                                messageFn();
                                message.success('Data Recieved! Now you can modify and submit', 1);
                                this.processJobs(data);
                            }
                        });
                    }
                })
                .catch(e => {
                    this.setState({ on_break: true });
                    this.clearTimers();
                });
        }, PING_JOB_TIME);
    };
    showNotification = () => {
        let notification = new Notification('New request', {
            icon: '/favicon.ico',
            body: 'New request on annotation tool.'
        });

        notification.onclick = function () {
            window.focus();
        };
    };

    stopTimer = () => {
        this.remaining_timer && clearInterval(this.remaining_timer);
    };

    startPingTimer = () => {
        this.job_ping_timer = setInterval(() => {
            if (this.state.current_job?.id) {
                let data = {
                    active_work_id: this.state.current_job.id,
                    source_type: this.AppStore.current_source_type
                };
                getAPI(PING, data)
                    .then(data => {
                        if (data && data.reallocated) {
                            message.warn('Your job reallocated!!', 5);
                            this.resetState();
                        }
                    })
                    .catch(e => {});
            }
        }, PING_JOB_TIME);
    };

    stopPingTimer = () => {
        this.job_ping_timer && clearInterval(this.job_ping_timer);
    };

    startSaveTimer = () => {
        this.job_save_timer = setInterval(() => {
            if (this.state.current_job.id && !this.state.on_break && this.state.time) {
                const url = interpolate(SAVE_JOB, [this.state.current_job.id]);
                const data = {
                    layers: this.getLayersFromJob()
                };
                postAPI(url, data).catch(e => {});
            }
        }, SOURCE_TYPE_DATA[this.AppStore.current_source_type]?.job_save_time);
    };

    stopSaveTimer = () => {
        this.job_save_timer && clearInterval(this.job_save_timer);
    };

    getLayersFromJob = () => {
        let layers = [];
        this.state.current_job &&
            this.state.current_job.layers.forEach(job => {
                const layer_type = job.layer_type;
                const geojson = mapObj.getLayerGeoJson(layer_type);
                layers.push({ layer_name: layer_type, geojson });
            });
        return layers;
    };

    handleSubmitClick = () => {
        if (
            this.AppStore.current_source_type == SOURCE_TYPE.FALCON_QA ||
            this.AppStore.current_source_type == SOURCE_TYPE.RESIDENTIAL_QA
        ) {
            const userRatingLayerCount = Object.keys(this.state.meta_data?.ratings).length;
            const layersCount = this.AppStore.current_layers.length;
            if (userRatingLayerCount < layersCount) {
                this.setState({ isRatingModalVisible: true });
                return;
            }
        }
        this.handleSubmit();
        this.setState({ isRatingModalVisible: false });
    };

    isValidData = () => {
        const invalidPolys = mapObj.getInvalidPolys();
        if (invalidPolys.length) {
            highlightFeatures(invalidPolys);
            message.error(ERROR_INVALID_FEATURES);
            return false;
        }
        return true;
    };

    handleSubmit = () => {
        if (this.state.incomplete_jobs?.length) {
            message.error('All jobs are not loaded yet!!', 1);
            return;
        }
        if (this.state.current_job && this.state.current_job.id) {
            if (!this.isValidData()) {
                return;
            }

            this.stopSaveTimer();
            this.stopPingTimer();
            this.stopTimer();
            this.AppStore.setCurrentTool(TOOL_TYPE.PAN);
            const url = interpolate(SUBMIT_JOB, [this.state.current_job.id]);
            const layers = {};
            this.state.current_job.layers.forEach(job => {
                const layer_type = job.layer_type;
                const layer_json = mapObj.getLayerGeoJson(layer_type);
                layers[layer_type] = layer_json;
            });
            const time_in_qc = (Date.now() - this.state.qc_start_time) / 1000 || 0;
            const data_tobe_sent = {
                layers: layers,
                total_time_to_complete_job: parseInt(time_in_qc),
                total_time_to_download_layers: 0,
                meta_data: this.state.meta_data
            };
            this.setState({ is_submit_btn_loading: true });
            postAPI(url, data_tobe_sent)
                .then(() => {
                    message.success('Request submitted. Waiting for new requests.');
                    this.resetState();
                })
                .catch(e => {
                    message.error('Job not submitted.Please try to resubmit the job or refresh the page');
                })
                .finally(() => {
                    this.setState({ is_submit_btn_loading: false });
                });
        }
    };

    setMetaData = (meta_data, callback_fn = () => {}) => {
        this.setState({ meta_data: meta_data }, callback_fn);
    };
    updateMetaData = () => {
        if (this.state.current_job && this.state.current_job.id) {
            const url = interpolate(UPDATE_META_DATA, [this.state.current_job.id]);
            postAPI(url, { meta_data: this.state.meta_data });
        }
    };

    hideRatingModal = () => {
        this.setState({ isRatingModalVisible: false });
    };

    hideLeaderboard = () => {
        this.setState({ isLeaderBoardVisible: false });
    };

    startTimer = time => {
        let sec = time;

        this.remaining_timer && clearInterval(this.remaining_timer);

        this.remaining_timer = setInterval(() => {
            sec--;
            this.setState({ time: sec });
            if (sec == 10) {
                playSound('warn');
            } else if (sec == 0) {
                this.stopTimer();
                this.handleSubmitClick();
            }
        }, 1000);
    };

    getTimer = () => {
        let time = this.state.time;
        const blinking = time && time <= 10;
        let cls = 'remaining-time timer';
        let color = 'blue';
        if (blinking) {
            color = 'red';
            cls += ' blink';
        } else if (time == 0) {
            color = '';
        }

        time = new Date(time * 1000).toISOString().substr(11, 8);

        return (
            <Tag color={color} className={cls}>
                Time left: {time}
            </Tag>
        );
    };

    getCurrentStateIcon = () => {
        if (getParameterFromUrl('demo')) {
            return (
                <Tag className='ml' color='geekblue'>
                    DEMO PAGE
                </Tag>
            );
        }

        const waiting_icon = <img title='Searching jobs' className='waiting-icon' src='/img/live.gif' />;
        const break_icon = <img title='On Break' className='break-icon' src='/img/wait.jpg' />;

        if (!this.state.current_job && !this.state.on_break) {
            return waiting_icon;
        } else if (this.state.on_break) {
            return break_icon;
        }
    };

    handleBreakChange = v => {
        this.setState({ on_break: v }, () => {
            if (v) {
                this.clearTimers();
            } else {
                this.listenToJob();
            }
        });
    };

    snoozeTime = SNOOZE_TIME => {
        !this.demoPage && this.state.current_job?.id && this.startTimer(this.state.time + SNOOZE_TIME);
    };

    getSourceConfigComponent = () => {
        const source_type = this.AppStore.current_source_type;

        // let ConfigComponent = null;
        // if (source_type == SOURCE_TYPE.LAWNX) {
        //     ConfigComponent = LawnXConfig;
        // } else if (source_type == SOURCE_TYPE.FALCON_QA || source_type == SOURCE_TYPE.RESIDENTIAL_QA) {
        //     ConfigComponent = FalconQAConfig;
        // } else if (source_type == SOURCE_TYPE.FALCON || source_type == SOURCE_TYPE.RESIDENTIAL) {
        //     ConfigComponent = FalconConfig;
        // }

        const ConfigComponent = SOURCE_TYPE_DATA[source_type]?.config_component;
        if (ConfigComponent) {
            return (
                <ConfigComponent
                    AppStore={this.AppStore}
                    mapObj={mapObj}
                    meta_data={this.state.meta_data}
                    setMetaData={this.setMetaData}
                    updateMetaData={this.updateMetaData}
                    handleSubmit={this.handleSubmit}
                    snoozeTime={this.snoozeTime}
                    showModal={this.state.isRatingModalVisible}
                    hideRatingModal={this.hideRatingModal}
                    ratingModalTime={this.state.ratingModalTime}
                    resetLifeline={this.state.resetLifeline}
                />
            );
        }
        return null;
    };
    getSourceMapComponent = () => {
        const source_type = this.AppStore.current_source_type;
        let SourceMapComponent = SOURCE_TYPE_DATA[source_type]?.map_component;

        // if (source_type == SOURCE_TYPE.LAWNX) {
        //     SourceMapComponent = LawnXMapComponent();
        // } else if (
        //     source_type == SOURCE_TYPE.FALCON ||
        //     source_type == SOURCE_TYPE.FALCON_QA ||
        //     source_type == SOURCE_TYPE.RESIDENTIAL ||
        //     source_type == SOURCE_TYPE.RESIDENTIAL_QA
        // ) {
        //     SourceMapComponent = FalconMapComponent();
        // }

        if (SourceMapComponent) {
            return <SourceMapComponent />; // reason: Warning: Functions are not valid as a React child. This may happen if you return a Component instead of <Component /> from render. Or maybe you meant to call this function rather than return it.
        }

        return SourceMapComponent;
    };
    getSourceDefaultMetaData = () => {
        const source_type = this.AppStore.current_source_type;
        return SOURCE_TYPE_DATA[source_type]?.meta_data || {};

        // let default_meta_data = null;
        // if (source_type == SOURCE_TYPE.LAWNX) {
        //     default_meta_data = META_DATA;
        // } else if (source_type == SOURCE_TYPE.FALCON_QA || source_type == SOURCE_TYPE.RESIDENTIAL_QA) {
        //     default_meta_data = FALCON_QA_DEFAULT_META_DATA;
        // } else if (source_type == SOURCE_TYPE.FALCON || source_type == SOURCE_TYPE.RESIDENTIAL) {
        //     default_meta_data = FALCON_DEFAULT_META_DATA;
        // }
        // return default_meta_data;
    };

    getJobIDDisplay = () => {
        if (!this.state.current_job) {
            return null;
        }
        const job_id_map = this.state.current_job.layers.map(job => {
            const layer_color = LAYER_COLOR[job.layer_type] || 'black';

            return (
                <div key={job.id} className='job-id-map-entry text-center'>
                    <hr />
                    <div>
                        <svg className='legend-line'>
                            <line x1='0' y1='7' x2='20' y2='7' style={{ stroke: layer_color, strokeWidth: 4 }} />
                        </svg>
                        <span>{job.layer_type}</span>
                    </div>
                    <div>{job.id}</div>
                </div>
            );
        });
        return (
            <div className='job-id-map'>
                <div className='text-center'>
                    <b>JOB ID MAP</b>
                </div>
                <div>{job_id_map}</div>
            </div>
        );
    };

    getBreakOption() {
        return (
            <div className={'text-left mt'}>
                <span>Taking Break: </span> <Switch onChange={this.handleBreakChange} size='small' />
            </div>
        );
    }
    render() {
        const job = this.state.current_job?.id;
        return (
            <div>
                <Row gutter={8}>
                    <Col span={3} className={'viewport-height overflow-auto left-panel'}>
                        <Header AppStore={this.AppStore} />
                        <Divider />

                        <div>
                            {this.getTimer()}
                            {this.getCurrentStateIcon()}
                        </div>
                        {this.getBreakOption()}
                        {this.demoPage && this.getModes()}

                        <Divider />
                        {job && this.getSourceConfigComponent()}
                        <Divider>
                            <Button
                                className={'submit-btn'}
                                disabled={!job}
                                block
                                loading={this.state.is_submit_btn_loading}
                                danger
                                ghost
                                onClick={this.handleSubmitClick}>
                                Submit
                            </Button>
                        </Divider>
                        {this.getJobIDDisplay()}
                    </Col>
                    <Col span={19} className={'viewport-height overflow-auto'}>
                        <div
                            className={'toolbox-container text-right'}
                            style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ToolBox />
                        </div>
                        <MapComponent SourceMapComponent={this.getSourceMapComponent()} />
                    </Col>
                    <Col span={2} className={'viewport-height overflow-auto'}>
                        <NavLink
                            to={
                                (this.AppStore.user.carto_role === CARTO_ROLES_ENUM.QC
                                    ? '/qc-ratings/'
                                    : '/qa-ratings/') + this.AppStore.user.id
                            }>
                            <div className='ratingContainer'>
                                <img src='/img/star.png' alt='star' />
                                <p>{this.AppStore.user.ratings ? this.AppStore.user.ratings : 'NA'}</p>
                            </div>
                        </NavLink>
                        <a
                            style={{ textDecoration: 'underline', marginLeft: '15px' }}
                            onClick={() => {
                                this.setState({ isLeaderBoardVisible: !this.stateisLeaderBoardVisible });
                            }}>
                            Leaderboard
                        </a>
                        <Leaderboard
                            isLeaderBoardVisible={this.state.isLeaderBoardVisible}
                            hideLeaderboard={this.hideLeaderboard}
                        />
                        <div>
                            <Radio.Group
                                value={this.state.current_top_image}
                                onChange={e => {
                                    mapObj.moveImageryLayerToTop(e.target.value);
                                    this.setState({ current_top_image: e.target.value });
                                }}>
                                {this.state.current_images.map(val => {
                                    return (
                                        <Radio key={val} value={val}>
                                            {val}
                                        </Radio>
                                    );
                                })}
                            </Radio.Group>
                        </div>
                        <div
                            className={'text-center'}
                            style={{ padding: '7px', width: '100%', fontWeight: 'bold', marginTop: '30px' }}>
                            Shortcuts
                        </div>
                        <ShortcutKeys sourceType={this.AppStore.current_source_type} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AnnotationPage;

function playSound(type) {
    let audios = {
        load: '/alert.mp3',
        warn: '/warning.mp3'
    };
    if (process.env.NODE_ENV != 'development') {
        try {
            let audio = new Audio();
            audio.src = audios[type];
            audio.autoplay = true;
            audio.play();
        } catch (err) {
            console.log(err);
        }
    }
}

const {
    COPY_TOOL,
    COPY,
    REMOVE_HOLE,
    EDGE,
    CLEAR_ATTR,
    DELETE,
    SELECT,
    CUT,
    MOVE,
    PAN,
    DRAW,
    PASTE,
    HOLE,
    CIRCULAR_HOLE,
    SPLIT_LINE,
    CIRCULAR_FILL,
    FILL_RING,
    DRAW_RECTANGLE,
    DRAW_POINT,
    DRAW_POLYLINE,
    RESIZE,
    CUT_FEATURES,
    LINE_SNIPPING_TOOL,
    MEASURE_TOOL
} = TOOL_TYPE;
const ALL_TOOLS = [
    RESIZE,
    CUT,
    SELECT,
    CLEAR_ATTR,
    DELETE,
    DRAW,
    HOLE,
    CIRCULAR_HOLE,
    PAN,
    DRAW_POLYLINE,
    DRAW_POINT,
    DRAW_RECTANGLE,
    FILL_RING,
    CIRCULAR_FILL,
    SPLIT_LINE,
    LINE_SNIPPING_TOOL,
    MEASURE_TOOL,
    CUT_FEATURES,
    // COPY,
    PASTE,
    MOVE,
    EDGE,
    REMOVE_HOLE,
    COPY_TOOL
];
export const SOURCE_TYPE_DATA = {
    [SOURCE_TYPE.RESIDENTIAL]: {
        dummy_layers: [LAWN, BEDS],
        style: null,
        meta_data: RESIDENTIAL_DEFAULT_META_DATA,
        job_save_time: 60000,
        config_component: ResidentialConfig,
        map_component: ResidentialMapComponent,
        tools: ALL_TOOLS,
        context_menu_fn: function (mapBase, e) {
            return ResidentialContextMenuFn(mapBase, e);
        },
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.RESIDENTIAL_QA]: {
        dummy_layers: [LAWN, BEDS],
        tools: ALL_TOOLS,
        style: null,
        meta_data: RESIDENTIAL_QA_DEFAULT_META_DATA,
        job_save_time: 60000,
        config_component: ResidentialQAConfig,
        map_component: ResidentialMapComponent,
        context_menu_fn: function (mapBase, e) {
            return ResidentialContextMenuFn(mapBase, e);
        },
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.RESIDENTIAL_RAPID]: {
        dummy_layers: [LAWN, BEDS, EDITABLE_PARCEL, CANOPY, BUILDING_FOOTPRINT],
        tools: ALL_TOOLS,
        style: null,
        meta_data: RESIDENTIAL_RAPID_DEFAULT_META_DATA,
        job_save_time: 60000,
        map_component: ResidentialRapidMapComponent,
        config_component: ResidentialRapidConfig,
        context_menu_fn: function (mapBase, e) {
            return ResidentialRapidContextMenuFn(mapBase, e);
        },
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.RESIDENTIAL_RAPID_TRAIN]: {
        dummy_layers: [LAWN, BEDS, EDITABLE_PARCEL, CANOPY, BUILDING_FOOTPRINT],
        tools: ALL_TOOLS,
        style: null,
        meta_data: RESIDENTIAL_RAPID_DEFAULT_META_DATA,
        job_save_time: 60000,
        map_component: ResidentialRapidMapComponent,
        config_component: ResidentialRapidConfig,
        context_menu_fn: function (mapBase, e) {
            return ResidentialRapidContextMenuFn(mapBase, e);
        },
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FALCON]: {
        dummy_layers: [TREE, LAWN_EDGE, MOWER_60, MOWER_48, MOWER_36, MOWER_21],
        tools: ALL_TOOLS,
        style: null,
        meta_data: FALCON_DEFAULT_META_DATA,
        job_save_time: 60000,
        config_component: FalconConfig,
        map_component: FalconMapComponent,
        context_menu_fn: function (mapBase, e) {
            return FalconContextMenuFn(mapBase, e);
        },
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FALCON_QA]: {
        dummy_layers: [MULCH_BED, TREE, ROADWAYS, LAWN_EDGE, DRIVEWAY, GRAVEL_BED],
        tools: ALL_TOOLS,
        style: null,
        meta_data: FALCON_QA_DEFAULT_META_DATA,
        job_save_time: 60000,
        config_component: FalconQAConfig,
        map_component: FalconMapComponent,
        context_menu_fn: function (mapBase, e) {
            return FalconContextMenuFn(mapBase, e);
        },
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_QC]: {
        dummy_layers: [MULCH_BED, TREE, ROADWAYS, LAWN, DRIVEWAY, GRAVEL_BED],
        tools: ALL_TOOLS,
        style: null,
        meta_data: FALCON_QA_DEFAULT_META_DATA,
        job_save_time: 60000,
        config_component: FalconQAConfig,
        map_component: FalconMapComponent,
        context_menu_fn: function (mapBase, e) {
            return FalconContextMenuFn(mapBase, e);
        },
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.LAWNX]: {
        dummy_layers: [LAWN, LAWN_EDGE],
        tools: [RESIZE, SELECT, DELETE, CLEAR_ATTR, CUT, DRAW, HOLE],
        style: LawnXVectorStyleFn,
        meta_data: META_DATA,
        job_save_time: 5000,
        config_component: LawnXConfig,
        map_component: LawnXMapComponent,
        context_menu_fn: function (mapBase, e) {
            return LawnXContextMenuFn(mapBase, e);
        },
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    }
};
