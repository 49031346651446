import React from 'react';
import { List, Radio, Tag } from 'antd';
import { LAWN_ATTRIBUTE, PARCEL } from '../../../Constants/MapConstant';
import { Stroke, Style, Fill } from 'ol/style';
import { undoRedoPush } from '../OlMap/MapInit';

const FEATURE_COLOR = {
    F: '#ff8197',
    B: '#f3e24d',
    R: '#4eb6e0',
    L: '#5bd85b'
};

const LAWNX_CATEGORY = {
    TREE: 1,
    NO_BUILDING: 2,
    UNDER_CONSTRUCTION: 3,
    MINOR_CHANGES: 4,
    EXTRA_TIME: 5
};

const layer_colors_list = (
    <div className='lawnx-layer-color-table'>
        <table>
            <tbody>
                <tr>
                    <td style={{ backgroundColor: FEATURE_COLOR['F'] }}>Front - 1</td>
                    <td style={{ backgroundColor: FEATURE_COLOR['B'] }}>Back - 2</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: FEATURE_COLOR['L'] }}>Left - 3</td>
                    <td style={{ backgroundColor: FEATURE_COLOR['R'] }}>Right - 4</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: 'red' }}>No Attribute</td>
                    <td style={{ backgroundColor: 'greenyellow' }}>Parcel</td>
                </tr>
            </tbody>
        </table>
    </div>
);
export const META_DATA = { Tree: false, Building: false, category: 4, eta_flag: 0, under_construction: false };

const CATEGORIES = [
    {
        title: 'Tree > 50%',
        value: LAWNX_CATEGORY.TREE
    },
    {
        title: 'No Building',
        value: LAWNX_CATEGORY.NO_BUILDING
    },
    {
        title: 'Under Construction',
        value: LAWNX_CATEGORY.UNDER_CONSTRUCTION
    },
    {
        title: 'Minor Changes',
        value: LAWNX_CATEGORY.MINOR_CHANGES
    },
    {
        title: 'Fencing',
        value: LAWNX_CATEGORY.EXTRA_TIME
    }
];

export class LawnXConfig extends React.Component {
    constructor(props) {
        super(props);
        this.props.setMetaData(META_DATA);
    }
    render() {
        const handleCatgoriesChange = e => {
            const val = e.target.value;
            let name;
            if (val == LAWNX_CATEGORY.TREE) {
                name = 'Tree';
            } else if (val == LAWNX_CATEGORY.NO_BUILDING) {
                name = 'Building';
            } else if (val == LAWNX_CATEGORY.UNDER_CONSTRUCTION) {
                name = 'under_construction';
            }
            let data = {
                ...this.props.meta_data,
                category: val
            };
            if (name) {
                data[name] = true;
            }

            let size;
            if (val == LAWNX_CATEGORY.EXTRA_TIME || val == 6 || val == 7) {
                size = 1; //Large
            } else {
                size = 0; //Regular
            }
            data['eta_flag'] = size;
            this.props.setMetaData(data, () => {
                this.props.updateMetaData();
                // handle action
                if (val == LAWNX_CATEGORY.TREE || val == LAWNX_CATEGORY.NO_BUILDING) {
                    this.props.mapObj.setFeatureAttribute('F');
                } else if (val == LAWNX_CATEGORY.UNDER_CONSTRUCTION) {
                    this.props.mapObj.deleteAll();
                    this.props.handleSubmit();
                } else if (val == LAWNX_CATEGORY.EXTRA_TIME) {
                    // Merge all
                    // this.props.mapObj.mergeAll();
                    // this.props.snoozeTime(15);
                    this.props.snoozeTime(30);
                }
                // else if (val == 6) {
                //     // Delete all
                //     // this.props.mapObj.deleteAll();
                //     this.props.snoozeTime(15);
                // } else if (val == 7) {
                //     this.props.snoozeTime(30);
                // }
            });
        };
        return (
            <>
                <Radio.Group
                    value={this.props.meta_data.category}
                    name='category-radio'
                    onChange={handleCatgoriesChange}>
                    {CATEGORIES.map((cat, i) => (
                        <Radio key={i} style={{ marginTop: 20 }} value={cat.value}>
                            {cat.title}
                        </Radio>
                    ))}
                </Radio.Group>
                <br />
                <br />
                {layer_colors_list}
            </>
        );
    }
}
export function LawnXMapComponent() {
    return (
        <select size='4' placeholder='Select lawn' id='select-attr'>
            <option value='F'>Front</option>
            <option value='B'>Back</option>
            <option value='L'>Left</option>
            <option value='R'>Right</option>
        </select>
    );
}

export function LawnXContextMenuFn(_this, e) {
    let loc = _this.map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
        return { layer, feature };
    });
    if (loc && loc.layer && loc.layer.get('id') != PARCEL) {
        // Show select
        let select = document.getElementById('select-attr');
        select.style.display = 'block';
        select.style.top = e.originalEvent.clientY + 'px';
        select.style.left = e.originalEvent.clientX + 5 + 'px';
        select.value = loc.feature.getProperties()[LAWN_ATTRIBUTE];
        select.focus();
        select.onchange = () => {
            loc.feature.setProperties({ [LAWN_ATTRIBUTE]: select.value });
            select.style.display = 'none';
            undoRedoPush();
        };
        select.onclick = () => {
            select.style.display = 'none';
        };
    }
}
export function LawnXVectorStyleFn(feature, resolution) {
    const lawn_type = feature.getProperties()[LAWN_ATTRIBUTE];
    return new Style({
        stroke: new Stroke({
            color: FEATURE_COLOR[lawn_type] || 'red',
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        })
    });
}
