import React from 'react';
import { Checkbox } from 'antd';
import Slider from 'antd/es/slider';
import { LAYER_COLOR } from '../../Constants/MapConstant';
import { mapObj } from './OlMap/MapInit';
export default function MapLegend(props) {
    const components = props.layers?.length
        ? [
              props.layers.map(layerId => {
                  const className = layerId == props.selected_layer_id ? 'selected-layer' : '';
                  const layer_color = LAYER_COLOR[layerId] || 'black';
                  const layer_obj = mapObj.map.getLayerById(layerId);

                  return (
                      <LayerBox
                          key={layerId}
                          className={className}
                          id={layerId}
                          title={layerId}
                          layerColor={layer_color}
                          isVisible={layer_obj && layer_obj.getVisible()}
                          setVisible={v => layer_obj && layer_obj.setVisible(v)}
                          onSelectLayer={id => props.setSelectedLayerId(id)}
                      />
                  );
              }),
              <LayersOpacitySlider
                  key={'opac'}
                  setLayersOpacity={props.setLayersOpacity}
                  layers_opacity={props.layers_opacity}
              />
          ]
        : [];

    return <div>{components}</div>;
}

function LayerBox(props) {
    const { className, isVisible, layerColor, id, title } = props;
    return (
        <div>
            <Checkbox
                defaultChecked={isVisible}
                onChange={e => {
                    props.setVisible(e.target.checked);
                }}
            />
            <div className='legend-entry'>
                <SVGLine strokeColor={layerColor} />
                <span className={className + ' legend-layer'} onClick={() => props.onSelectLayer(id)}>
                    {title}
                </span>
            </div>
        </div>
    );
}

function SVGLine(props) {
    const { strokeColor = '#000000', strokeWidth = 4 } = props;
    return (
        <svg className='legend-line'>
            <line x1='0' y1='6' x2='20' y2='6' style={{ stroke: strokeColor, strokeWidth }} />
        </svg>
    );
}

function LayersOpacitySlider(props) {
    return (
        <div className='layer-opacity-slider-container'>
            <i>Fill Opacity</i>
            <Slider
                onChange={props.setLayersOpacity}
                value={props.layers_opacity}
                defaultValue={0}
                step={0.1}
                min={0}
                max={1}
            />
        </div>
    );
}
