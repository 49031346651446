import MapBase from './MapBase';
import MapTool from './MapTools';
import { TOOL_TYPE } from '../../../Constants/MapConstant';
import UndoRedo from './Tools/UndoRedo';

export const mapObj = new MapBase();
export const toolsObj = new MapTool(mapObj);
export let undoRedoObj;

export const initOlMap = function (AppStore) {
    mapObj.init(AppStore);
    undoRedoObj = new UndoRedo(mapObj, AppStore);
};

export const initTool = function (type, layer_id = null) {
    toolsObj.offAllTools();
    toolsObj.activateRightClickTool();
    undoRedoObj.init();

    if (type == TOOL_TYPE.DRAW) {
        toolsObj.activateDrawTool(layer_id);
    } else if (type == TOOL_TYPE.DRAW_RECTANGLE) {
        toolsObj.activateDrawReactangleTool(layer_id);
    } else if (type == TOOL_TYPE.CIRCULAR_HOLE) {
        toolsObj.activateCircularHoleTool(layer_id);
    } else if (type == TOOL_TYPE.CIRCULAR_FILL) {
        toolsObj.activateCircularFillTool(layer_id);
    } else if (type == TOOL_TYPE.DRAW_POLYLINE) {
        toolsObj.activateDrawPolylineTool(layer_id);
    } else if (type == TOOL_TYPE.DRAW_POINT) {
        toolsObj.activateDrawPointTool(layer_id);
    } else if (type == TOOL_TYPE.SPLIT_LINE) {
        toolsObj.activateSplitLineTool(layer_id);
    } else if (type == TOOL_TYPE.FILL_RING) {
        toolsObj.activateFillRingTool(layer_id);
    } else if (type == TOOL_TYPE.HOLE) {
        toolsObj.activateHoleTool(layer_id);
    } else if (type == TOOL_TYPE.MOVE) {
        toolsObj.activateMoveTool(layer_id);
    } else if (type == TOOL_TYPE.CUT) {
        toolsObj.activateCutTool(layer_id);
    } else if (type == TOOL_TYPE.SELECT) {
        toolsObj.activateSelectTool(layer_id);
    } else if (type == TOOL_TYPE.EDGE) {
        toolsObj.activateEdgeTool(layer_id);
    } else if (type == TOOL_TYPE.RESIZE) {
        toolsObj.activateReshapeTool(layer_id);
    } else if (type == TOOL_TYPE.REMOVE_HOLE) {
        toolsObj.activateRemoveHoleTool(layer_id);
    } else if (type == TOOL_TYPE.COPY_TOOL) {
        toolsObj.activateCopyTool(layer_id);
    } else if (type == TOOL_TYPE.LINE_SNIPPING_TOOL) {
        toolsObj.activateLineSnippingTool(layer_id);
    } else if (type == TOOL_TYPE.MEASURE_TOOL) {
        toolsObj.activateMeasureTool();
    }
};

export const undoRedoPush = function () {
    undoRedoObj.push();
};
