import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import 'antd/dist/antd.less';
import './Styles/index.less';
import { Provider } from 'mobx-react';
import S_App from './Stores/AppStore';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

let AppStore = new S_App();
const stores = {
    AppStore
};

if (process.env.NODE_ENV != 'development') {
    Sentry.init({
        dsn: 'https://a584833b7dfa46228a05f71308691785@o439343.ingest.sentry.io/5406044',
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({
                levels: ['error']
            })
        ],
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV
    });
    if (AppStore.user.email) {
        Sentry.configureScope(function (scope) {
            scope.setUser({ user_email: AppStore.user.email });
        });
    }
}

ReactDOM.render(
    <Router>
        <Provider {...stores}>
            <App />
        </Provider>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
