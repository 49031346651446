import * as turf from '@turf/turf';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Draw } from 'ol/interaction';
import GeoJSON from 'ol/format/GeoJSON';
import { drawStyle } from '../MapBase';
import { LAWN_ATTRIBUTE } from '../../../../Constants/MapConstant';
import { undoRedoPush } from '../MapInit';
import { captureException, setExtra } from '@sentry/react';
import { highlightFeatures, isInvalidPoly } from '../../../../Utils/HelperFunctions';
import { message } from 'antd';
import { ERROR_INVALID_FEATURES } from '../../../../Constants/Messages';

class CutPolygon {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.lineLayer = null;
        this.targetPolygon = null;
    }

    init(id) {
        this.off();
        this.selected_layer_id = id;
        let sourceDrawnLines = new VectorSource({ wrapX: false });
        this.lineLayer = new VectorLayer({
            source: sourceDrawnLines
        });
        this.mapObj.map.addLayer(this.lineLayer);

        this.draw = new Draw({
            source: sourceDrawnLines,
            type: 'LineString',
            style: drawStyle(id),
            dragVertexDelay: 0,
            snapTolerance: 1,
            condition: e => {
                const mouseClick = e.originalEvent.which;
                if (mouseClick == 3 || mouseClick == 2) {
                    return false;
                }
                return true;
            }
        });

        this.mapObj.map.addInteraction(this.draw);
        this.draw.on('drawend', this.drawEnd);
        window.addEventListener('keydown', this.handleKeyDawn);
    }

    handleKeyDawn = e => {
        if (e.code == 'Backspace') {
            this.draw.removeLastPoint();
        } else if (e.code == 'Space') {
            this.draw.finishDrawing();
        }
    };

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.lineLayer && this.mapObj.map.removeLayer(this.lineLayer);
        window.removeEventListener('keydown', this.handleKeyDawn);
    }

    drawEnd = e => {
        let layerPoly = this.mapObj.map.getLayerById(this.selected_layer_id);
        if (layerPoly) {
            let sourcePoly = layerPoly.getSource();
            let featuresPoly = sourcePoly.getFeatures();

            let FormatGeoJSON = new GeoJSON();

            let drawnGeoJSON = FormatGeoJSON.writeFeatureObject(e.feature, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            });
            let drawnGeometry = turf.getGeom(drawnGeoJSON);
            let invalidPolys = [];
            if (drawnGeometry.type == 'LineString') {
                featuresPoly.forEach(featurePoly => {
                    let featureGeo = FormatGeoJSON.writeFeatureObject(featurePoly, {
                        dataProjection: 'EPSG:4326',
                        featureProjection: 'EPSG:3857'
                    });
                    const fcopy = Object.assign({}, featureGeo);

                    let polygon = turf.getGeom(featureGeo);
                    const lawnType = featurePoly.getProperties()[LAWN_ATTRIBUTE];
                    try {
                        let cutPolygon = this.polygonCut(polygon, drawnGeometry, lawnType);
                        if (cutPolygon != null) {
                            let features = FormatGeoJSON.readFeatures(cutPolygon, {
                                dataProjection: 'EPSG:4326',
                                featureProjection: 'EPSG:3857'
                            });
                            sourcePoly.addFeatures(features);
                            sourcePoly.removeFeature(featurePoly);
                            invalidPolys = [...invalidPolys, ...features.filter(f => isInvalidPoly(f))];
                        }
                    } catch (err) {
                        setExtra('feature', JSON.stringify(fcopy));
                        setExtra('drawn', JSON.stringify(drawnGeometry));
                        setExtra('Request ID', localStorage.getItem('job_id'));
                        captureException(err);
                    }
                });
                if (invalidPolys.length) {
                    highlightFeatures(invalidPolys);
                    message.error(ERROR_INVALID_FEATURES);
                }
            }
            undoRedoPush();
        }
        this.mapObj.map.removeLayer(this.lineLayer);
    };

    polygonCut(polygon, line, type) {
        const THICK_LINE_UNITS = 'inches';
        const THICK_LINE_WIDTH = 20;
        let i, j, id, intersectPoints, lineCoords, forCut, forSelect;
        let thickLineString, thickLinePolygon, clipped, polyg, intersect;
        let polyCoords = [];
        let cutPolyGeoms = [];
        let cutFeatures = [];
        let offsetLine = [];
        let retVal = null;
        let idPrefix = 'cut_';

        if ((polygon.type != 'Polygon' && polygon.type != 'MultiPolygon') || line.type != 'LineString') {
            return retVal;
        }

        if (typeof idPrefix === 'undefined') {
            idPrefix = '';
        }

        intersectPoints = turf.lineIntersect(polygon, line);
        if (intersectPoints.features.length == 0) {
            return retVal;
        }

        lineCoords = turf.getCoords(line);
        if (
            turf.booleanWithin(turf.point(lineCoords[0]), polygon) ||
            turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)
        ) {
            return retVal;
        }

        offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });
        offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });

        for (i = 0; i <= 1; i++) {
            forCut = i;
            forSelect = (i + 1) % 2;
            polyCoords = [];
            for (j = 0; j < line.coordinates.length; j++) {
                polyCoords.push(line.coordinates[j]);
            }
            for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
                polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
            }
            polyCoords.push(line.coordinates[0]);

            thickLineString = turf.lineString(polyCoords);
            thickLinePolygon = turf.lineToPolygon(thickLineString);
            clipped = turf.difference(polygon, thickLinePolygon);

            cutPolyGeoms = [];
            for (j = 0; j < clipped.geometry.coordinates.length; j++) {
                polyg = turf.polygon(clipped.geometry.coordinates[j]);
                intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
                if (intersect.features.length > 0) {
                    cutPolyGeoms.push(polyg.geometry.coordinates);
                }
            }

            cutPolyGeoms.forEach(function (geometry, index) {
                id = idPrefix + (i + 1) + '.' + (index + 1);
                cutFeatures.push(turf.polygon(geometry, { [LAWN_ATTRIBUTE]: type }));
            });
        }
        if (cutFeatures.length > 0) {
            retVal = turf.featureCollection(cutFeatures);
        }

        return retVal;
    }
}

export default CutPolygon;
