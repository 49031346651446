import React from 'react';
import { inject, observer } from 'mobx-react';
import { Layout, Row, Col, Input, Button } from 'antd';
import { LOGIN } from '../Constants/Urls';
import { loginAPI } from '../Utils/ApiCalls';
import * as Sentry from '@sentry/react';

@inject('AppStore')
@observer
class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.AppStore = this.props.AppStore;
        this.state = {
            is_loading: false
        };
    }

    handleInputChange = (name, val) => {
        this.setState({ [name]: val });
    };

    validateToken = e => {
        e.preventDefault();
        let user_data = {
            email: this.state.email,
            password: this.state.password
        };
        this.setState({ is_loading: true });
        loginAPI(LOGIN, user_data)
            .then(data => {
                this.setState({ is_loading: false });
                this.AppStore.loginUser(data);
                Sentry.configureScope(function (scope) {
                    scope.setUser({ user_email: data.email });
                });
            })
            .catch(err => {
                this.setState({ is_loading: false });
            });
    };

    render() {
        return (
            <Layout className='pageLayout'>
                <Row>
                    <Col span={12} style={{ height: '100%' }}>
                        <div className='PageProductSection'></div>
                    </Col>
                    <Col span={12} style={{ padding: '35px' }}>
                        <div className='FormWrapper'>
                            <h1>Web Annotation Tool</h1>
                            <br />
                            <div className='login-form'>
                                <form onSubmit={this.validateToken}>
                                    <Input
                                        required
                                        onChange={e => this.handleInputChange('email', e.target.value)}
                                        type='email'
                                        placeholder='Enter email...'
                                    />
                                    <Input.Password
                                        className='mt'
                                        required
                                        onChange={e => this.handleInputChange('password', e.target.value)}
                                        placeholder='Enter password...'
                                    />
                                    <Button
                                        htmlType='submit'
                                        type='primary'
                                        className='mt fw'
                                        loading={this.state.is_loading}>
                                        Login
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Layout>
        );
    }
}
export default LoginPage;
