import { observable, action, reaction } from 'mobx';
import { initTool, mapObj } from '../MainPage/Annotation/OlMap/MapInit';
import { SOURCE_TYPE_DATA } from '../MainPage/Annotation/AnnotationPage';

/**
	This is the global state container for FMG-Dashboard
*/
class S_App {
    constructor() {
        this.user = JSON.parse(localStorage.getItem('user')) || {};
        this.is_logged_in = localStorage.getItem('token') || undefined;
    }

    @observable is_logged_in = false;
    @observable user = {};
    @observable current_tool = 'pan';
    @observable selected_layer_id = null;
    @observable snapping_mode = false;
    @observable current_source_type = localStorage.getItem('source_type');
    @observable current_layers = [];
    @observable layers_opacity = 0;
    @observable ortho_image_visible = false;

    @action loginUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', user.token);
        localStorage.setItem('source_type', user.source_type || '');
        this.user = user;
        this.is_logged_in = true;
        this.current_source_type = user.source_type;
    }

    @action logoutUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('source_type');
        this.is_logged_in = false;
        window.location.reload();
    }

    @action setCurrentSourceType(val) {
        this.current_source_type = val;
    }
    @action setSelectedLayerId(val) {
        this.selected_layer_id = val;
        if (val) {
            localStorage.setItem('selected_layer_id', val);
            initTool(this.current_tool, this.selected_layer_id);
        }
    }

    @action setCurrentTool(val) {
        this.current_tool = val;
        if (val) {
            localStorage.setItem('current_tool', val);
            initTool(this.current_tool, this.selected_layer_id);
        }
    }
    @action setCurrentLayers(val) {
        this.current_layers = val;
    }

    @action setLayersOpacity(val) {
        this.layers_opacity = val;
        mapObj.setLayersOpacity(val);
    }

    @action setSnappingMode(val) {
        this.snapping_mode = val;
    }

    @action setOrthoImageVisible(val) {
        this.ortho_image_visible = val;
    }

    ContextMenuFn(_this, e) {
        return SOURCE_TYPE_DATA[this.current_source_type]?.context_menu_fn(_this, e);
    }
}

export default S_App;
