import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { GET_LEADERBOARD } from '../../Constants/Urls';
import { getAPI } from '../../Utils/ApiCalls';

const Leaderboard = ({ isLeaderBoardVisible, hideLeaderboard }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getLeaderboard();
    }, []);

    const getLeaderboard = () => {
        getAPI(GET_LEADERBOARD).then(data => {
            if (data) {
                setData(data);
            }
        });
    };

    const columns = [
        {
            title: 'Rank',
            render: (text, record) => {
                const index = data.findIndex(d => d.id === record.id);
                return <span>{index + 1}</span>;
            }
        },
        {
            title: 'Name',
            render: (text, record) => {
                return <span>{record.name}</span>;
            }
        },
        {
            title: 'Email',
            render: (text, record) => {
                return <span>{record.email}</span>;
            }
        },
        {
            title: 'Ratings',
            render: (text, record) => {
                return <span>{record.ratings}</span>;
            }
        }
    ];

    return (
        <>
            <Modal
                title='Leaderboard'
                visible={isLeaderBoardVisible}
                onOk={hideLeaderboard}
                onCancel={hideLeaderboard}
                width={1350}
                footer={null}>
                <Table
                    rowKey='id'
                    size='small'
                    bordered
                    pagination={false}
                    rowSelection={null}
                    columns={columns}
                    dataSource={data}
                />
            </Modal>
        </>
    );
};

export default Leaderboard;
