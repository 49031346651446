import { captureException } from '@sentry/react';
import kinks from '@turf/kinks';
import { message } from 'antd';
import { Feature } from 'ol';
import { GEO_JSON, HighlightFeatureStyleFn } from '../Constants/MapConstant';
import { ERROR_INVALID_FEATURE } from '../Constants/Messages';

/**
 * @name downloadFileWithUrl
 * @function
 * @description Download a file from URL
 * @param {String} url URL of file
 * @param {String} name Name of file while download (Optional)
 */
export const downloadFileWithUrl = function (url, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 * @name objectToArray
 * @function
 * @description Converst a object to array in following format
 * obj = {1: 'test', 2: 'test 2'}
 * return [{id: 1, value: 'test'}, {id: 2, value: 'test 2'}]
 * @param {Object} object Source Object
 */
export const objectToArray = function (obj) {
    let array = [];
    if (Object.keys(obj).length) {
        for (let i in obj) {
            let temp_obj = {
                id: i,
                value: obj[i]
            };
            array.push(temp_obj);
        }
    }
    return array;
};

/**
 * @name getParameterFromUrl
 * @function
 * @description Get parameter from URL
 * @param {String} url URL
 * @param {String} name Parameter name to be get
 */
export const getParameterFromUrl = name => {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * @name createCirclePointCoords
 * @function
 * @description Calculate circle coordinates from center,radius
 * @param {Number} circleCenterX circleCenterX
 * @param {Number} circleCenterX circleCenterY
 * @param {Number} circleRadius circleRadius
 * @param {Number} pointsToFind No of points to found
 */
export const createCirclePointCoords = (circleCenterX, circleCenterY, circleRadius, pointsToFind = 360) => {
    const angleToAdd = 360 / pointsToFind;
    let coords = [];
    let angle = 0;
    for (var i = 0; i < pointsToFind; i++) {
        angle = angle + angleToAdd;
        let coordX = circleCenterX + circleRadius * Math.cos((angle * Math.PI) / 180);
        let coordY = circleCenterY + circleRadius * Math.sin((angle * Math.PI) / 180);
        coords.push([coordX, coordY]);
    }

    return coords;
};
/**
 * @name getDateString
 * @function
 * @description Get date string from timestamp
 * @param {int} timestamp timestamp
 */
export const getDateString = timestamp => {
    const date = new Date(timestamp);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    return day + ' ' + month + ' ' + year;
};

/**
 * @name isInvalidPoly
 * @function
 * @description To check the validity of a polygon
 * @param {Feature} poly poly
 */

export const isInvalidPoly = function (poly) {
    try {
        if (poly) {
            const _poly = GEO_JSON.writeFeatureObject(poly);
            const invalid = kinks(_poly);
            return invalid.features?.length;
        }
    } catch (e) {
        captureException(e);
    }
};

// export const checkFeaturesValidity = function (features, highlight = true, showMsg = true) {
//     let flag = false;
//     for (const f of features) {
//         if (isInvalidPoly(f)) {
//             flag = true;
//             highlight && highlightFeature(f);
//         }
//     }
//     if (flag && showMsg) {
//         message.error('Invalid shapes. Please delete and redraw the highlighted shapes.');
//     }
// };

/**
 * @name highlightFeatureIfInvalid
 * @function
 * @description To hightlight an invalid feature
 * @param {Feature} feature feature
 * @param {Boolean} showErrorMsg showErrorMsg default true
 */

export const highlightFeatureIfInvalid = function (feature, showErrorMsg = true) {
    if (isInvalidPoly(feature)) {
        highlightFeature(feature);
        showErrorMsg && message.error(ERROR_INVALID_FEATURE);
    }
};

/**
 * @name highlightFeatures
 * @function
 * @description To hightlight a feature by changing its style for specific time
 * @param {Feature} feature feature
 */

export const highlightFeatures = features => {
    for (const f of features) {
        highlightFeature(f);
    }
};

/**
 * @name highlightFeature
 * @function
 * @description To hightlight a feature by changing its style for specific time
 * @param {Feature} feature feature
 */

export const highlightFeature = feature => {
    feature.setStyle(HighlightFeatureStyleFn());
    setTimeout(() => {
        feature.setStyle();
    }, 3000);
};
