import React from 'react';
import { Checkbox } from 'antd';
export const FALCON_DEFAULT_META_DATA = {};
import { Stroke, Style, Fill } from 'ol/style';
import { mapObj, undoRedoPush } from '../OlMap/MapInit';
import { toolsObj } from '../OlMap/MapInit';
import { GEOMETRY_TYPES, LAYER, PARCEL } from '../../../Constants/MapConstant';
// import { observe } from 'mobx';
// import { inject, observer } from 'mobx-react';

const FEATURE_COLOR = {
    S: '#D80090',
    H: '#1921FF'
};
const LAWN_EDGE_ATTRIBUTE = 'edge_type';
export const layer_colors_list = (
    <div className='lawnx-layer-color-table'>
        <table>
            <tbody>
                <tr>
                    <td style={{ backgroundColor: FEATURE_COLOR['S'] }}>Soft Edge</td>
                    <td style={{ backgroundColor: FEATURE_COLOR['H'] }}>Hard Edge</td>
                </tr>
            </tbody>
        </table>
    </div>
);
export class FalconConfig extends React.Component {
    constructor(props) {
        super(props);
        this.AppStore = this.props.AppStore;
        const obj_clone = JSON.parse(JSON.stringify(FALCON_DEFAULT_META_DATA));
        this.props.setMetaData(obj_clone);
    }

    parcelCorrectionHandler = () => {
        return (
            <Checkbox
                onChange={e => {
                    if (e.target.checked) {
                        this.props.setMetaData({ tree_overhang: true });
                    } else {
                        const obj_clone = JSON.parse(
                            JSON.stringify({ tree_overhang: false, ...FALCON_DEFAULT_META_DATA })
                        );
                        this.props.setMetaData(obj_clone);
                    }
                }}>
                Canopy Layer?
            </Checkbox>
        );
    };
    ratingHandler = (layer, value) => {
        let current_meta_data = this.props.meta_data;
        current_meta_data.ratings[layer] = value;
        const obj_clone = JSON.parse(JSON.stringify(current_meta_data));
        this.props.setMetaData(obj_clone);
    };
    render() {
        const layers = this.AppStore.current_layers;
        if (layers.indexOf(LAYER.CANOPY) > -1) {
            return this.parcelCorrectionHandler();
        } else if (layers.indexOf(LAYER.LAWN_EDGE) > -1) {
            return layer_colors_list;
        }
        return null;
    }
}

export function FalconMapComponent() {
    return (
        <div className='context-menu'>
            <select size='2' placeholder='Select Edge Type' id='select-attr'>
                <option value='S'>Soft Edge</option>
                <option value='H'>Hard Edge</option>
            </select>
            <LayersDropdown />
        </div>
    );
}

// const LayersDropdown = inject('AppStore')(
//     observer(({ AppStore }) => {
//         const layers = AppStore.current_layers || [];
//         console.log(layers);
//         return (
//             <select size='2' placeholder='Select Edge Type' id='layers-dropdown'>
//                 <option value='S'>Soft Edge</option>
//                 <option value='H'>Hard Edge</option>
//             </select>
//         );
//     })
// );

const LayersDropdown = () => {
    const layers = mapObj.map
        ? mapObj.map
              .getLayers()
              .getArray()
              .filter(l => !mapObj.isIgnorableLayer(l))
              .map(l => l.get('id'))
        : [];
    return layers.length ? (
        <select size={layers.length} placeholder='Select Layer' id='layers-dropdown'>
            {layers.map(lId => (
                <option key={lId} value={lId}>
                    {lId}
                </option>
            ))}
        </select>
    ) : null;
};

var select;
export function FalconContextMenuFn(_this, e) {
    const selectTool = toolsObj.getSelectTool();
    let features =
        selectTool.select && selectTool.select.getFeatures().getLength()
            ? selectTool.select.getFeatures().getArray()
            : [];
    let layer = selectTool.layer?.get('id');
    if (!features.length) {
        const px = e.pixel;
        const feature = _this.map.forEachFeatureAtPixel(
            px,
            (f, l) => {
                layer = l ? l.get('id') : null;
                return f;
            },
            {
                layerFilter: l => ![PARCEL, LAYER.PARCEL].includes(l.get('id')),
                hitTolerance: 5
            }
        );
        if (
            layer &&
            feature &&
            (feature.getGeometry().getType() === GEOMETRY_TYPES.POLYGON ||
                feature.getGeometry().getType() === GEOMETRY_TYPES.LINESTRING)
        ) {
            features.push(feature);
        } else {
            hide();
            return;
        }
    }
    let containerId = 'select-attr',
        fn = handleLawnEdgeAttribution;
    if (layer !== LAYER.LAWN_EDGE) {
        containerId = 'layers-dropdown';
        fn = handleLayersDropdown;
    }

    hide(); // hide first if any other dropdown already opened
    select = document.getElementById(containerId);
    select.style.top = e.originalEvent.clientY + 'px';
    select.style.left = e.originalEvent.clientX + 5 + 'px';
    show();
    select.focus();
    fn();

    function handleLawnEdgeAttribution() {
        select.value = features[0].getProperties()[LAWN_EDGE_ATTRIBUTE];
        select.onchange = () => {
            features.forEach(feat => {
                feat.setProperties({ [LAWN_EDGE_ATTRIBUTE]: select.value });
            });
            onTaskComplete();
        };
    }

    function handleLayersDropdown() {
        select.value = layer;
        select.onchange = () => {
            const selectedLayer = mapObj.map.getLayerById(select.value);
            const currentLayer = mapObj.map.getLayerById(layer);
            currentLayer &&
                selectedLayer &&
                features.forEach(feat => {
                    currentLayer.getSource().removeFeature(feat);
                    selectedLayer.getSource().addFeature(feat);
                });
            onTaskComplete();
        };
    }

    function onTaskComplete() {
        selectTool.emptyFeatureArray();
        undoRedoPush();
        hide();
    }

    function show() {
        select.style.display = 'block';
    }

    function hide() {
        if (select) select.style.display = 'none';
    }

    document.onclick = e => {
        if (e.target === select || select.contains(e.target)) {
            return;
        }
        hide();
    };
}
export function FalconVectorStyleFn(feature, resolution) {
    const edge_type = feature.getProperties()[LAWN_EDGE_ATTRIBUTE];
    return new Style({
        stroke: new Stroke({
            color: FEATURE_COLOR[edge_type] || 'red',
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        })
    });
}
