import React from 'react';
import { inject, observer } from 'mobx-react';
import MapLegend from './MapLegend';
@inject('AppStore')
@observer
class MapComponent extends React.Component {
    /**
     * @constructor
     * @description Map component
     * @param {Object} props Props
     */
    constructor(props) {
        super(props);
        this.AppStore = this.props.AppStore;
        this.state = {};
    }
    setSelectedLayerId = layer => {
        this.AppStore.setSelectedLayerId(layer);
    };
    setLayersOpacity = val => {
        this.AppStore.setLayersOpacity(val);
    };
    render() {
        return (
            <div className='relative'>
                <div id='map'></div>
                <div className='layer-box'>
                    <div className='mb'>
                        <MapLegend
                            layers={this.AppStore.current_layers}
                            selected_layer_id={this.AppStore.selected_layer_id}
                            setSelectedLayerId={this.setSelectedLayerId}
                            setLayersOpacity={this.setLayersOpacity}
                            layers_opacity={this.AppStore.layers_opacity}
                        />
                    </div>
                </div>
                {this.props.SourceMapComponent}
            </div>
        );
    }
}

export default MapComponent;
