import { asString } from 'ol/color';
import GeoJSON from 'ol/format/GeoJSON';
export const LAYER_NAME = 'lawnx_layer';
export const LAWN_ATTRIBUTE = 'LawnType';
export const DATA_PROJECTION = 'EPSG:4326';
export const FEATURE_PROJECTION = 'EPSG:3857';
export const PARCEL = 'parcel'; //boundary

import { Stroke, Style, Fill, Circle } from 'ol/style';

export const GEO_JSON_OBJECT_PROJECTION = {
    dataProjection: DATA_PROJECTION,
    featureProjection: FEATURE_PROJECTION
};

export const GEO_JSON = {
    writeFeaturesObject: (features, projection = true) => {
        if (!projection) {
            return new GeoJSON().writeFeaturesObject(features);
        }
        return new GeoJSON().writeFeaturesObject(features, GEO_JSON_OBJECT_PROJECTION);
    },
    writeFeatureObject: (feature, projection = true) => {
        if (!projection) {
            return new GeoJSON().writeFeatureObject(feature);
        }
        return new GeoJSON().writeFeatureObject(feature, GEO_JSON_OBJECT_PROJECTION);
    },
    readFeature: (feature, projection = true) => {
        if (!projection) {
            return new GeoJSON().readFeature(feature);
        }
        return new GeoJSON().readFeature(feature, GEO_JSON_OBJECT_PROJECTION);
    },
    readFeatures: (features, projection = true) => {
        if (!projection) {
            return new GeoJSON().readFeatures(features);
        }
        return new GeoJSON().readFeatures(features, GEO_JSON_OBJECT_PROJECTION);
    },
    writeFeatures: (features, projection = true) => {
        if (!projection) {
            return new GeoJSON().writeFeatures(features);
        }
        return new GeoJSON().writeFeatures(features, GEO_JSON_OBJECT_PROJECTION);
    }
};

export function StyleGen(color) {
    return new Style({
        stroke: new Stroke({
            color: color || 'black',
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        }),
        // Add visualisation for point features
        image: new Circle({
            radius: 5,
            fill: new Fill({
                color: color || 'black'
            })
        })
    });
}

export const GEOMETRY_TYPES = {
    POINT: 'Point',
    LINESTRING: 'LineString',
    POLYGON: 'Polygon'
};
export const DATA_STATUS = {
    PARTIAL: 1,
    COMPLETE: 2
};
export const GEOMETRY_TYPES_ALL = [GEOMETRY_TYPES.POINT, GEOMETRY_TYPES.LINESTRING, GEOMETRY_TYPES.POLYGON];

export const LAYER = {
    PARCEL: 'parcel_layer', // editable layer
    CANOPY: 'canopy_layer',
    LAWN: 'lawn_layer',
    BEDS: 'beds_layer',
    HEDGE: 'hedge_layer',
    SIDEWALK: 'sidewalk_layer',
    DRIVEWAY: 'driveway_layer',
    PAVEMENT: 'pavement_layer',
    PARKING_SPACE: 'parking_space_layer',
    DRIVELANE: 'drivelane_layer',
    DEBUG: 'debug_layer',
    TREE: 'tree_layer',
    CURB: 'curb_layer',
    PARKING_LOT: 'parking_lot_layer',
    LAWN_EDGE: 'lawn_edge_layer',
    DRIVABLE_AREA: 'drivable_area_layer',
    MULCH_BED: 'mulch_bed_layer',
    GRAVEL_BED: 'gravel_bed_layer',
    ROADWAYS: 'roadways_layer',
    BUILDING_FOOTPRINT: 'building_footprint_layer',
    MOWER_60: 'mower_60_layer',
    MOWER_48: 'mower_48_layer',
    MOWER_36: 'mower_36_layer',
    MOWER_21: 'mower_21_layer'
};

export const LAYER_COLOR = {
    [LAYER_NAME]: 'red',
    [PARCEL]: 'greenyellow', // boundary layer
    [LAYER.PARCEL]: '#1473E6', // editable layer
    [LAYER.CANOPY]: 'brown',
    [LAYER.LAWN]: '#FF00A7',
    [LAYER.BEDS]: '#00863E',
    [LAYER.HEDGE]: '#795235',
    [LAYER.SIDEWALK]: '#0AAFFF',
    [LAYER.DRIVEWAY]: '#7551E9',
    [LAYER.PAVEMENT]: '#FF7D51',
    [LAYER.PARKING_SPACE]: '#12009D',
    [LAYER.DRIVELANE]: '#F5E03C',
    [LAYER.DEBUG]: 'blue',
    [LAYER.TREE]: '#CBFF3C',
    [LAYER.CURB]: '#FFF507',
    [LAYER.PARKING_LOT]: '#B551F6',
    [LAYER.LAWN_EDGE]: 'red',
    [LAYER.DRIVABLE_AREA]: '#F5E03C',
    [LAYER.MULCH_BED]: '#006400', // dark green
    [LAYER.GRAVEL_BED]: '#808080', // grey
    [LAYER.ROADWAYS]: '#800080', // purple
    [LAYER.BUILDING_FOOTPRINT]: '#ff0000', // purple
    [LAYER.MOWER_60]: '#40ff81',
    [LAYER.MOWER_48]: '#ea2b1e',
    [LAYER.MOWER_36]: '#f0f647',
    [LAYER.MOWER_21]: '#d00eb9'
};
// TODO: Create constant enum for layer_type keys
// Mapping layer type to geometry type
export const LAYER_TYPE_GEOMETRY_MAP = {
    [LAYER_NAME]: GEOMETRY_TYPES.POLYGON,
    [PARCEL]: GEOMETRY_TYPES.POLYGON, // boundary layer
    [LAYER.PARCEL]: GEOMETRY_TYPES.POLYGON, // editable layer
    [LAYER.CANOPY]: null,
    [LAYER.LAWN]: GEOMETRY_TYPES.POLYGON,
    [LAYER.BEDS]: GEOMETRY_TYPES.POLYGON,
    [LAYER.HEDGE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.SIDEWALK]: GEOMETRY_TYPES.POLYGON,
    [LAYER.DRIVEWAY]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PAVEMENT]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PARKING_SPACE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.DRIVELANE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.DEBUG]: GEOMETRY_TYPES.POLYGON,
    [LAYER.TREE]: GEOMETRY_TYPES.POINT,
    [LAYER.CURB]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.PARKING_LOT]: GEOMETRY_TYPES.POLYGON,
    [LAYER.LAWN_EDGE]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.DRIVABLE_AREA]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MULCH_BED]: GEOMETRY_TYPES.POLYGON,
    [LAYER.GRAVEL_BED]: GEOMETRY_TYPES.POLYGON,
    [LAYER.ROADWAYS]: GEOMETRY_TYPES.POLYGON,
    [LAYER.BUILDING_FOOTPRINT]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MOWER_60]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MOWER_48]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MOWER_36]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MOWER_21]: GEOMETRY_TYPES.POLYGON
};

export const TOOL_TYPE = {
    DRAW: 'draw',
    DRAW_POINT: 'draw_point',
    DRAW_POLYLINE: 'draw_polyline',
    DRAW_RECTANGLE: 'draw_rectangle',
    CIRCULAR_HOLE: 'circular_hole',
    CIRCULAR_FILL: 'circular_fill',
    CUT: 'cut',
    SPLIT_LINE: 'split_line',
    HOLE: 'hole',
    FILL_RING: 'fill_ring',
    SELECT: 'select',
    DELETE: 'delete',
    CUT_FEATURES: 'cut_features',
    COPY: 'copy',
    PASTE: 'paste',
    RESIZE: 'resize',
    MOVE: 'move',
    PAN: 'pan',
    EDGE: 'edge',
    CLEAR_ATTR: 'clear',
    REMOVE_HOLE: 'remove_hole',
    COPY_TOOL: 'copy_tool',
    RIGHT_CLICK_TOOL: 'right_click_tool',
    LINE_SNIPPING_TOOL: 'line_snipping_tool',
    MEASURE_TOOL: 'measure_tool'
};

export const LAYER_ZINDEX = {
    [LAYER.BEDS]: 300,
    [LAYER.HEDGE]: 200,
    [LAYER.LAWN]: 150
};

export const SOURCE_TYPE = {
    LAWNX: 'lawnx',
    FALCON: 'falcon',
    FALCON_QA: 'falcon-qa',
    RESIDENTIAL: 'residential',
    RESIDENTIAL_QA: 'residential-qa',
    RESIDENTIAL_RAPID: 'residential-rapid',
    RESIDENTIAL_RAPID_TRAIN: 'residential-rapid-train',
    FMS_QC: "fms-qc",
};

export const SOURCE_TYPE_LAYERS_MAP = {
    [SOURCE_TYPE.RESIDENTIAL]: [LAYER.LAWN, LAYER.BEDS, LAYER.BUILDING_FOOTPRINT],
    [SOURCE_TYPE.RESIDENTIAL_QA]: [LAYER.LAWN, LAYER.BEDS, LAYER.BUILDING_FOOTPRINT],
    [SOURCE_TYPE.RESIDENTIAL_RAPID]: [LAYER.LAWN, LAYER.BEDS, LAYER.BUILDING_FOOTPRINT],
    [SOURCE_TYPE.RESIDENTIAL_RAPID_TRAIN]: [LAYER.LAWN, LAYER.BEDS, LAYER.BUILDING_FOOTPRINT]
};

export const LIFELINES = [
    { time: 60, title: 'Lifeline1- 60s' },
    { time: 120, title: 'Lifeline2- 120s' }
];

export const LAYER_TYPE = {
    LAWN_EDGE: 'lawn_edge_layer'
};

const FEATURE_COLOR = {
    S: '#D80090',
    H: '#1921FF'
};
const LAWN_EDGE_ATTRIBUTE = 'edge_type';

export function LawnEdgeVectorStyleFn(feature, resolution) {
    const edge_type = feature.getProperties()[LAWN_EDGE_ATTRIBUTE];
    return new Style({
        stroke: new Stroke({
            color: FEATURE_COLOR[edge_type] || 'red',
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        })
    });
}

export function ModifiedStyleGenFn(id) {
    if (id === LAYER.LAWN_EDGE) {
        return LawnEdgeVectorStyleFn;
    }
    const color = LAYER_COLOR[id] || 'black';
    return new Style({
        stroke: new Stroke({
            color: color,
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        }),
        // Add visualisation for point features
        image: new Circle({
            radius: 5,
            fill: new Fill({
                color: color
            })
        })
    });
}

export function HighlightFeatureStyleFn() {
    const color = asString([255, 0, 0, 0.6]);
    return new Style({
        stroke: new Stroke({
            color: color,
            width: 3
        }),
        fill: new Fill({
            color: color
        })
        // Add visualisation for point features
        // image: new Circle({
        //     radius: 5,
        //     fill: new Fill({
        //         color: color
        //     })
        // })
    });
}

export const DUMMY_PARCEL = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.82997640967369, 33.381180848655696],
                        [-84.82998177409172, 33.38079673560248],
                        [-84.82968538999557, 33.38080009519921],
                        [-84.82968002557755, 33.38117076990922],
                        [-84.82997640967369, 33.381180848655696]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_BUILDING = {
    crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
    name: 'building1596105856',
    type: 'FeatureCollection',
    features: [
        {
            id: 1,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.82984538458234, 33.380825973829445],
                        [-84.82984360927152, 33.38083117249698],
                        [-84.8298302152318, 33.38083453437877],
                        [-84.82982217880794, 33.38084013751508],
                        [-84.82981682119205, 33.38085358504222],
                        [-84.8298087847682, 33.380861429433054],
                        [-84.82978333609272, 33.380865911942095],
                        [-84.82976860264901, 33.38087823884198],
                        [-84.8297632450331, 33.380904013268996],
                        [-84.82975520860927, 33.38092642581423],
                        [-84.82974985099338, 33.38093314957781],
                        [-84.82974449337748, 33.38093090832328],
                        [-84.82974181456953, 33.38091297828709],
                        [-84.82974583278146, 33.38089953075995],
                        [-84.82974449337748, 33.3808804800965],
                        [-84.82974717218544, 33.38087823884198],
                        [-84.82974583278146, 33.380859188178526],
                        [-84.82974315397351, 33.380861429433054],
                        [-84.82974315397351, 33.38089616887817],
                        [-84.82973779635762, 33.38091634016888],
                        [-84.82973511754967, 33.38096452714113],
                        [-84.82973511754967, 33.38099702533172],
                        [-84.82974047516556, 33.381023920386006],
                        [-84.82974181456953, 33.381021679131486],
                        [-84.82975119039735, 33.381022799758746],
                        [-84.82975788741722, 33.38101495536791],
                        [-84.82976592384105, 33.3810183172497],
                        [-84.82978735430463, 33.38101607599518],
                        [-84.82982083940398, 33.38100711097708],
                        [-84.82985566390728, 33.380991422195414],
                        [-84.82986771854304, 33.380991422195414],
                        [-84.82987441556291, 33.38098806031363],
                        [-84.82988245198675, 33.380992542822675],
                        [-84.82989986423841, 33.380983577804585],
                        [-84.82989986423841, 33.3809275464415],
                        [-84.82990388245032, 33.3809185814234],
                        [-84.8299052218543, 33.38087935946924],
                        [-84.82990790066225, 33.38087823884198],
                        [-84.82990656125828, 33.38085358504222],
                        [-84.82990254304636, 33.38084349939686],
                        [-84.82990254304636, 33.380827446985734],
                        [-84.829924, 33.380828],
                        [-84.829904, 33.381152],
                        [-84.829715, 33.381141],
                        [-84.82973, 33.380823],
                        [-84.82984538458234, 33.380825973829445]
                    ]
                ]
            },
            properties: { LawnType: 'F' }
        }
    ]
};

export const DUMMY_SHAPE = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: { LawnType: 'F' },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.82990130782127, 33.38104982486028],
                        [-84.82989460229874, 33.38099943104024],
                        [-84.82976585626602, 33.38101846870902],
                        [-84.82977524399757, 33.3810666227938],
                        [-84.8298342525959, 33.38111589671342],
                        [-84.82990130782127, 33.38104982486028]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_2 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: { LawnType: 'F' },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.83001664280891, 33.381103578236136],
                        [-84.83009710907936, 33.381077821414344],
                        [-84.830122590065, 33.381026307747895],
                        [-84.83010247349739, 33.38098263309374],
                        [-84.83003675937653, 33.38097479405094],
                        [-84.82995495200157, 33.38103190706096],
                        [-84.82995495200157, 33.381096859065956],
                        [-84.83001664280891, 33.381103578236136]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_3 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.8298516869545, 33.380971434460925],
                        [-84.8298704624176, 33.380947917327354],
                        [-84.82988119125366, 33.38091208168301],
                        [-84.82986778020859, 33.3808751261593],
                        [-84.82983022928238, 33.38083817061988],
                        [-84.82979267835617, 33.380828091833685],
                        [-84.82973501086235, 33.38084153021503],
                        [-84.82973769307137, 33.380884085075586],
                        [-84.82975378632545, 33.380936718690094],
                        [-84.8297806084156, 33.380984872820136],
                        [-84.8298516869545, 33.380971434460925]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_4 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.82978194952011, 33.38108566044788],
                        [-84.82965186238289, 33.38108566044788],
                        [-84.82965186238289, 33.381128215188944],
                        [-84.82978194952011, 33.381128215188944],
                        [-84.82978194952011, 33.38108566044788]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_5 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.83009308576584, 33.38107110224218],
                        [-84.8301413655281, 33.381001110834724],
                        [-84.83008034527302, 33.38092831971118],
                        [-84.82998311519623, 33.38095799609968],
                        [-84.82992611825466, 33.38097255432428],
                        [-84.82989862561224, 33.38100615021795],
                        [-84.82989527285099, 33.38103022726708],
                        [-84.8299227654934, 33.381045345410776],
                        [-84.82999451458454, 33.381043665617185],
                        [-84.83009308576584, 33.38107110224218]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_LINESTRING = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                edge_type: 'H'
            },
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-84.82982132447529, 33.380960965282],
                    [-84.829792365074013, 33.380991645043736],
                    [-84.829731005550542, 33.380969853811102],
                    [-84.829695738160879, 33.381014296456613],
                    [-84.82972355065516, 33.38104927711953]
                ]
            }
        }
    ]
};
export const DUMMY_SHAPE_POINT = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: { type: 'Point', coordinates: [-84.829749356062237, 33.38100168047982] }
        },
        {
            type: 'Feature',
            properties: {},
            geometry: { type: 'Point', coordinates: [-84.829761972039023, 33.381083971055709] }
        },
        {
            type: 'Feature',
            properties: {},
            geometry: { type: 'Point', coordinates: [-84.829712368312101, 33.381079956881273] }
        }
    ]
};
export const EMPTY_GEOJSON = {
    type: 'FeatureCollection',
    features: []
};

/**
 * Jobs
 */

export const JOB_STATUS_ENUM = {
    PENDING: 0,
    IN_PROGRESS: 1,
    COMPLETE: 2,
    FAILED: 3
};
export const JOB_STATUS = {
    [JOB_STATUS_ENUM.PENDING]: 'Pending',
    [JOB_STATUS_ENUM.IN_PROGRESS]: 'In Progress',
    [JOB_STATUS_ENUM.COMPLETE]: 'Complete',
    [JOB_STATUS_ENUM.FAILED]: 'Failed'
};
export const JOB_STATUS_COLOR = {
    [JOB_STATUS_ENUM.PENDING]: 'Pending',
    [JOB_STATUS_ENUM.IN_PROGRESS]: 'blue',
    [JOB_STATUS_ENUM.COMPLETE]: 'green',
    [JOB_STATUS_ENUM.FAILED]: 'red'
};

export const ROLES_ENUM = {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    CARTOGRAPHER: 3
};
export const ROLES = {
    [ROLES_ENUM.SUPER_ADMIN]: 'Super Admin',
    [ROLES_ENUM.ADMIN]: 'Admin',
    [ROLES_ENUM.CARTOGRAPHER]: 'Carto'
};

export const CARTO_ROLES_ENUM = {
    QC: 1,
    QA: 2
};
export const CARTO_ROLES = {
    [CARTO_ROLES_ENUM.QC]: 'QC',
    [CARTO_ROLES_ENUM.QA]: 'QA'
};

export const REQUEST_STATUS_ENUM = {
    DRAFT: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    FAILED: 4,
    QUEUED: 5,
    INVESTIGATING: 6
};

export const REQUEST_STATUS = {
    [REQUEST_STATUS_ENUM.DRAFT]: 'Draft',
    [REQUEST_STATUS_ENUM.IN_PROGRESS]: 'In Progress',
    [REQUEST_STATUS_ENUM.FAILED]: 'Failed',
    [REQUEST_STATUS_ENUM.COMPLETED]: 'Completed',
    [REQUEST_STATUS_ENUM.QUEUED]: 'Queued',
    [REQUEST_STATUS_ENUM.INVESTIGATING]: 'Investigating'
};

export const REQUEST_STATUS_COLOR = {
    [REQUEST_STATUS_ENUM.DRAFT]: '#FFA700',
    [REQUEST_STATUS_ENUM.IN_PROGRESS]: '#1473E6',
    [REQUEST_STATUS_ENUM.FAILED]: '#FF7474',
    [REQUEST_STATUS_ENUM.COMPLETED]: '#2DCA73',
    [REQUEST_STATUS_ENUM.QUEUED]: '#FF7D51',
    [REQUEST_STATUS_ENUM.INVESTIGATING]: '#989971'
};

const {
    LAWN,
    CANOPY,
    BEDS,
    LAWN_EDGE,
    SIDEWALK,
    MULCH_BED,
    GRAVEL_BED,
    DRIVEWAY,
    ROADWAYS,
    PARCEL: EDITABLE_PARCEL,
    TREE,
    BUILDING_FOOTPRINT,
    MOWER_60,
    MOWER_48,
    MOWER_36,
    MOWER_21
} = LAYER;
export const LAYER_DUMMY_JOB = {
    [SIDEWALK]: { id: 'test-id1', layer_type: SIDEWALK, original_json: DUMMY_SHAPE },
    [LAWN]: { id: 'test-id2', layer_type: LAWN, original_json: DUMMY_SHAPE_2 },
    [CANOPY]: { id: 'test-id3', layer_type: CANOPY, original_json: DUMMY_SHAPE_3 },
    [BEDS]: { id: 'test-id4', layer_type: BEDS, original_json: DUMMY_SHAPE_4 },
    [TREE]: { id: 'test-id5', layer_type: TREE, original_json: DUMMY_SHAPE_POINT },
    [LAWN_EDGE]: { id: 'test-id6', layer_type: LAWN_EDGE, original_json: DUMMY_SHAPE_LINESTRING },
    [EDITABLE_PARCEL]: { id: 'test-id7', layer_type: EDITABLE_PARCEL, original_json: DUMMY_PARCEL },
    [MULCH_BED]: { id: 'test-id8', layer_type: MULCH_BED, original_json: DUMMY_SHAPE_2 },
    [GRAVEL_BED]: { id: 'test-id9', layer_type: GRAVEL_BED, original_json: DUMMY_SHAPE_3 },
    [DRIVEWAY]: { id: 'test-id10', layer_type: DRIVEWAY, original_json: DUMMY_SHAPE },
    [ROADWAYS]: { id: 'test-id11', layer_type: ROADWAYS, original_json: DUMMY_SHAPE_4 },
    [BUILDING_FOOTPRINT]: { id: 'test-id12', layer_type: BUILDING_FOOTPRINT, original_json: DUMMY_BUILDING },
    [MOWER_60]: { id: 'test-id13', layer_type: MOWER_60, original_json: DUMMY_SHAPE_5 },
    [MOWER_48]: { id: 'test-id14', layer_type: MOWER_48, original_json: DUMMY_SHAPE_3 },
    [MOWER_36]: { id: 'test-id15', layer_type: MOWER_36, original_json: DUMMY_SHAPE_4 },
    [MOWER_21]: { id: 'test-id16', layer_type: MOWER_21, original_json: DUMMY_SHAPE }
};
