import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Menu, Dropdown, Button, message, Space, Tooltip, Switch } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { initTool, toolsObj, mapObj, undoRedoObj } from './OlMap/MapInit';
import { ReactComponent as DrawIcon } from './OlMap/Tools/icons/draw.svg';
import { ReactComponent as DrawPolylineIcon } from './OlMap/Tools/icons/draw_polyline.svg';
import { ReactComponent as DrawPointIcon } from './OlMap/Tools/icons/draw_point.svg';
import { ReactComponent as DrawReactangleIcon } from './OlMap/Tools/icons/three_point_rectangle.svg';
import { ReactComponent as CircularHoleIcon } from './OlMap/Tools/icons/circular_hole.svg';
import { ReactComponent as CircularFillIcon } from './OlMap/Tools/icons/circular_fill.svg';
import { ReactComponent as SelectIcon } from './OlMap/Tools/icons/select.svg';
import { ReactComponent as SplitLineIcon } from './OlMap/Tools/icons/split_line.svg';
import { ReactComponent as LineSnippingIcon } from './OlMap/Tools/icons/line_snipping.svg';
import { ReactComponent as OneClickLineSnippingIcon } from './OlMap/Tools/icons/one_click_line_snipping.svg';
import { ReactComponent as TwoClickLineSnippingIcon } from './OlMap/Tools/icons/two_click_line_snipping.svg';
import { ReactComponent as HoleIcon } from './OlMap/Tools/icons/hole.svg';
import { ReactComponent as FillRingIcon } from './OlMap/Tools/icons/fill_ring.svg';
import { ReactComponent as DeleteIcon } from './OlMap/Tools/icons/delete.svg';
import { ReactComponent as CutFeaturesIcon } from './OlMap/Tools/icons/cut_features.svg';
import { ReactComponent as CopyIcon } from './OlMap/Tools/icons/copy.svg';
import { ReactComponent as MeasureIcon } from './OlMap/Tools/icons/measure.svg';
import { ReactComponent as PasteIcon } from './OlMap/Tools/icons/paste.svg';
import { ReactComponent as CutIcon } from './OlMap/Tools/icons/split.svg';
import { ReactComponent as ResizeIcon } from './OlMap/Tools/icons/reshape.svg';
import { ReactComponent as MoveIcon } from './OlMap/Tools/icons/move.svg';
import { ReactComponent as PanIcon } from './OlMap/Tools/icons/pan.svg';
import { ReactComponent as EdgeIcon } from './OlMap/Tools/icons/edge.svg';
import { ReactComponent as ClearIcon } from './OlMap/Tools/icons/attribute.svg';
import { ReactComponent as RemoveHoleIcon } from './OlMap/Tools/icons/delete_ring.svg';
import { ReactComponent as RedoIcon } from './OlMap/Tools/icons/redo.svg';
import { ReactComponent as UndoIcon } from './OlMap/Tools/icons/undo.svg';
import { ReactComponent as CopyToolIcon } from './OlMap/Tools/icons/copyicon.svg';

import { Select } from 'antd';

const { Option } = Select;
import { SOURCE_TYPE_DATA } from './AnnotationPage';

import Icon from '@ant-design/icons';

import {
    TOOL_TYPE,
    GEOMETRY_TYPES_ALL,
    GEOMETRY_TYPES,
    LAYER_TYPE_GEOMETRY_MAP,
    SOURCE_TYPE,
    LAYER
} from '../../Constants/MapConstant';

import { SNIPPING_MODES } from './OlMap/Tools/LineSnippingTool';

const ALL = [
    SOURCE_TYPE.LAWNX,
    SOURCE_TYPE.FALCON,
    SOURCE_TYPE.FALCON_QA,
    SOURCE_TYPE.RESIDENTIAL,
    SOURCE_TYPE.RESIDENTIAL_QA
];

const TOOL_BTN = [
    {
        title: 'Reshape Feature',
        type: TOOL_TYPE.RESIZE,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: 'r',
        source_types: ALL
    },
    {
        title: 'Split Features',
        type: TOOL_TYPE.CUT,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: 'x',
        source_types: ALL
    },
    {
        title: 'Select Features',
        type: TOOL_TYPE.SELECT,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: 's',
        source_types: ALL
    },
    {
        title: 'Merge Selected',
        type: TOOL_TYPE.CLEAR_ATTR,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: 'c',
        source_types: ALL
    },
    {
        title: 'Delete Selected',
        type: TOOL_TYPE.DELETE,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: 'd',
        source_types: ALL
    },
    {
        title: 'Add Polygon',
        type: TOOL_TYPE.DRAW,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: 'a',
        source_types: ALL
    },
    {
        title: 'Add Ring',
        type: TOOL_TYPE.HOLE,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: 'Alt+R',
        source_types: ALL
    },
    {
        title: 'Add Circular Hole',
        type: TOOL_TYPE.CIRCULAR_HOLE,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: null,
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Pan Map',
        type: TOOL_TYPE.PAN,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: 'p',
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Add Polyline',
        type: TOOL_TYPE.DRAW_POLYLINE,
        allowed_geometries: [GEOMETRY_TYPES.LINESTRING],
        shortcut_key: null,
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Add Point',
        type: TOOL_TYPE.DRAW_POINT,
        allowed_geometries: [GEOMETRY_TYPES.POINT],
        shortcut_key: null,
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Add Rectangle',
        type: TOOL_TYPE.DRAW_RECTANGLE,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: null,
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Fill Ring',
        type: TOOL_TYPE.FILL_RING,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: 'f',
        source_types: ALL
    },
    {
        title: 'Circular Fill',
        type: TOOL_TYPE.CIRCULAR_FILL,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: null,
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Split Line',
        type: TOOL_TYPE.SPLIT_LINE,
        allowed_geometries: [GEOMETRY_TYPES.LINESTRING],
        shortcut_key: null,
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Line Snipping',
        type: TOOL_TYPE.LINE_SNIPPING_TOOL,
        allowed_geometries: [GEOMETRY_TYPES.LINESTRING],
        shortcut_key: null,
        multi_mode: true,
        modes: [
            { id: SNIPPING_MODES.ONE_CLICK, title: 'One Click Mode', icon: getModeIcon(SNIPPING_MODES.ONE_CLICK) },
            { id: SNIPPING_MODES.TWO_CLICK, title: 'Two Click Mode', icon: getModeIcon(SNIPPING_MODES.TWO_CLICK) }
        ],
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Measure Tool',
        type: TOOL_TYPE.MEASURE_TOOL,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: null
    },
    {
        title: 'Cut Selected',
        type: TOOL_TYPE.CUT_FEATURES,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: 'ctrl+x',
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    // {
    //     title: 'Copy Selected',
    //     type: TOOL_TYPE.COPY,
    //     allowed_geometries: GEOMETRY_TYPES_ALL,
    //     shortcut_key: 'ctrl+c',
    //     source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    // },
    {
        title: 'Paste',
        type: TOOL_TYPE.PASTE,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: 'ctrl+v',
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Move Feature',
        type: TOOL_TYPE.MOVE,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: 'm',
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Modify Vertex',
        type: TOOL_TYPE.EDGE,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: 'v',
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Delete Ring',
        type: TOOL_TYPE.REMOVE_HOLE,
        allowed_geometries: [GEOMETRY_TYPES.POLYGON],
        shortcut_key: 'shift+R',
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    },
    {
        title: 'Copy Coordinates',
        type: TOOL_TYPE.COPY_TOOL,
        allowed_geometries: GEOMETRY_TYPES_ALL,
        shortcut_key: null,
        source_types: [SOURCE_TYPE.FALCON_QA, SOURCE_TYPE.FALCON, SOURCE_TYPE.RESIDENTIAL, SOURCE_TYPE.RESIDENTIAL_QA]
    }
];

@inject('AppStore')
@observer
class ToolBox extends React.Component {
    /**
     * @constructor
     * @description ToolBox component
     * @param {Object} props Props
     */
    constructor(props) {
        super(props);
        this.AppStore = this.props.AppStore;
        this.state = {
            tools: TOOL_BTN,
            sourceType: this.AppStore.current_source_type
        };
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    handleDeleteClick = () => {
        const select = toolsObj.getSelectTool();
        select.deleteFeatures();
    };

    handleMergeClick = () => {
        const select = toolsObj.getSelectTool();
        select.mergeFeatures();
    };
    handleCutClick = () => {
        const select = toolsObj.getSelectTool();
        select.cut();
    };
    handleCopyClick = () => {
        const select = toolsObj.getSelectTool();
        select.copy();
    };
    handlePasteClick = id => {
        const select = toolsObj.getSelectTool();
        select.paste(id);
    };

    handleCopyToolClick = id => {
        this.AppStore.setCurrentTool('pan');
        const copyTool = toolsObj.getCopyTool();
        copyTool.init(id);
    };
    handleLineSnippingToolClick = (id, mode) => {
        this.AppStore.setCurrentTool(TOOL_TYPE.LINE_SNIPPING_TOOL);
        const tool = toolsObj.getLineSnippingTool();
        tool.init(id, mode);
    };

    handleKeydown = e => {
        if (e.keyCode == 27) {
            toolsObj.offAllTools();
            this.AppStore.setCurrentTool(null);
            if (document.getElementById('select-attr')) {
                document.getElementById('select-attr').style.display = 'none';
            }
        } else if (e.ctrlKey && e.key === 'z') {
            undoRedoObj.undo();
        } else if (e.ctrlKey && e.key === 'y') {
            undoRedoObj.redo();
        }
        // else if (e.ctrlKey && e.key === 'c') {
        //     this.handleCopyClick();
        // }
        else if (e.ctrlKey && e.key === 'x') {
            this.handleCutClick();
        } else if (e.ctrlKey && e.key === 'v') {
            this.handlePasteClick(this.AppStore.selected_layer_id);
        } else if (e.key === 'p') {
            this.AppStore.setCurrentTool(TOOL_TYPE.PAN);
        } else if (e.key === 'a') {
            this.AppStore.setCurrentTool(TOOL_TYPE.DRAW);
        } else if (e.key === 'x') {
            this.AppStore.setCurrentTool(TOOL_TYPE.CUT);
        } else if (!e.ctrlKey && e.key === 's') {
            this.AppStore.setCurrentTool(TOOL_TYPE.SELECT);
        } else if (e.key === 'm') {
            this.AppStore.setCurrentTool(TOOL_TYPE.MOVE);
        } else if (e.altKey && !e.shiftKey && e.key == 'r') {
            e.preventDefault();
            e.stopPropagation();
            this.AppStore.setCurrentTool(TOOL_TYPE.HOLE);
        } else if (!e.altKey && !e.ctrlKey && e.shiftKey && e.key == 'R') {
            e.preventDefault();
            e.stopPropagation();
            this.AppStore.setCurrentTool(TOOL_TYPE.REMOVE_HOLE);
        } else if (!e.altKey && !e.shiftKey && e.key == 'r') {
            this.AppStore.setCurrentTool(TOOL_TYPE.RESIZE);
        } else if (e.key === 'v') {
            this.AppStore.setCurrentTool(TOOL_TYPE.EDGE);
        } else if (e.key === 'f') {
            this.AppStore.setCurrentTool(TOOL_TYPE.FILL_RING);
        }
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    }

    handleToolClick = (type, mode) => {
        if (type == TOOL_TYPE.DELETE) {
            this.handleDeleteClick();
        } else if (type == TOOL_TYPE.CLEAR_ATTR) {
            this.handleMergeClick();
        } else if (type == TOOL_TYPE.CUT_FEATURES) {
            this.handleCutClick();
        } else if (type == TOOL_TYPE.COPY) {
            this.handleCopyClick();
        } else if (type == TOOL_TYPE.PASTE) {
            this.handlePasteClick(this.AppStore.selected_layer_id);
        } else if (type == TOOL_TYPE.COPY_TOOL) {
            this.handleCopyToolClick(this.AppStore.selected_layer_id);
        } else if (type == TOOL_TYPE.LINE_SNIPPING_TOOL) {
            this.handleLineSnippingToolClick(this.AppStore.selected_layer_id, mode);
        } else {
            this.AppStore.setCurrentTool(type);
        }
    };

    static getDerivedStateFromProps(props, state) {
        if (props.AppStore.current_source_type !== state.sourceType) {
            const sourceType = props.AppStore.current_source_type;
            let tools = state.tools;
            if (sourceType) {
                const sourceTools = SOURCE_TYPE_DATA[sourceType]?.tools ?? [];
                tools = TOOL_BTN.filter(tool => sourceTools.includes(tool.type));
            } else {
                tools = TOOL_BTN;
            }
            return { sourceType, tools };
        }

        return null;
    }

    /**
     * 
     * @param {checked status} value 
     * @description: It is used to toggle between layers
     * i.e. OSM and Google Map layer
     */
     toggleBaseLayer = (value) => {
        const layer = mapObj.map.getLayerById("google_layer")
        layer.setVisible(value)
    }

    /**
     * 
     * @param {checked status} value 
     * @description: It is used to toggle ortho image layer
     *
     */
    toggleImageLayer = (value) => {
        const layer = mapObj.map.getLayerById("image_static_ORTHO")
        layer?.setVisible(value)
        this.AppStore.setOrthoImageVisible(value)
    }

    render() {
        const btns = [];
        this.state.tools.forEach((tool, i) => {
            // if (tool.source_types.includes(this.AppStore.current_source_type)) {
            // }
            const cls = this.AppStore.current_tool == tool.type ? 'active-tool' : 'inactive-tool';
            let disabled = false;
            // Check if geometry type of selected layer is allowed for current tool type
            if (this.AppStore.selected_layer_id) {
                disabled =
                    tool.allowed_geometries.indexOf(LAYER_TYPE_GEOMETRY_MAP[this.AppStore.selected_layer_id]) == -1;
            }
            if (this.AppStore.selected_layer_id == LAYER.CANOPY && tool.type == TOOL_TYPE.PAN) {
                disabled = false;
                // this.AppStore.setCurrentTool(TOOL_TYPE.PAN);
            }
            // Turn off tool if current tool is disabled.
            // Happens if user changes layer type to different geometry
            // E.g. if drawPolygon is selected currently and user changes to a point layer type
            else if (this.AppStore.current_tool && this.AppStore.current_tool == tool.type && disabled) {
                toolsObj.offAllTools();
                this.AppStore.setCurrentTool(null);
            }
            btns.push(
                !tool.multi_mode ? (
                    <Tooltip key={i} placement='top' title={`${tool.title} (${tool.shortcut_key})`}>
                        <Button
                            key={tool.type}
                            icon={getIcon(tool.type)}
                            className={cls}
                            disabled={disabled}
                            onClick={() => this.handleToolClick(tool.type)}
                        />
                    </Tooltip>
                ) : (
                    <MultiModeToolComponent
                        key={i}
                        defaultValue={null}
                        onChange={value => {
                            this.handleToolClick(tool.type, value);
                        }}
                        className={cls}
                        disabled={disabled}
                        options={tool.modes}
                        icon={getIcon(tool.type)}
                        title={tool.title}
                    />
                )
            );
        });

        return (
            <>
                {btns.length ? (
                    <>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{marginRight: "1rem"}}>Toggle Map: <Switch onChange={this.toggleBaseLayer} /></div>
                            <div>Image: <Switch onChange={this.toggleImageLayer} checked={this.AppStore.ortho_image_visible} /></div>
                        </div>
                        <div>
                            <Button.Group size='large'>{btns}</Button.Group>
                            <Button.Group size='large' className='ml'>
                                <Tooltip title='Undo' placement='top'>
                                    <Button icon={getIcon('undo')} onClick={() => undoRedoObj.undo()} />
                                </Tooltip>
                                <Tooltip title='Redo' placement='top'>
                                    <Button icon={getIcon('redo')} onClick={() => undoRedoObj.redo()} />
                                </Tooltip>
                                {/* <label className={'ml'}>
                                    <input
                                        type={'checkbox'}
                                        checked={this.AppStore.snapping_mode}
                                        onChange={e => this.AppStore.setSnappingMode(e.target.checked)}
                                    />
                                    Snapping mode
                                </label> */}
                            </Button.Group>
                        </div>
                    </>
                ) : null}
            </>
        );
    }
}

export default ToolBox;

function getIcon(type) {
    const icons = {
        [TOOL_TYPE.DRAW]: DrawIcon,
        [TOOL_TYPE.DRAW_POLYLINE]: DrawPolylineIcon,
        [TOOL_TYPE.DRAW_POINT]: DrawPointIcon,
        [TOOL_TYPE.DRAW_RECTANGLE]: DrawReactangleIcon,
        [TOOL_TYPE.CIRCULAR_HOLE]: CircularHoleIcon,
        [TOOL_TYPE.CIRCULAR_FILL]: CircularFillIcon,
        [TOOL_TYPE.SELECT]: SelectIcon,
        [TOOL_TYPE.CUT]: CutIcon,
        [TOOL_TYPE.SPLIT_LINE]: SplitLineIcon,
        [TOOL_TYPE.HOLE]: HoleIcon,
        [TOOL_TYPE.FILL_RING]: FillRingIcon,
        [TOOL_TYPE.CUT_FEATURES]: CutFeaturesIcon,
        [TOOL_TYPE.COPY]: CopyIcon,
        [TOOL_TYPE.PASTE]: PasteIcon,
        [TOOL_TYPE.DELETE]: DeleteIcon,
        [TOOL_TYPE.RESIZE]: ResizeIcon,
        [TOOL_TYPE.MOVE]: MoveIcon,
        [TOOL_TYPE.PAN]: PanIcon,
        [TOOL_TYPE.EDGE]: EdgeIcon,
        [TOOL_TYPE.CLEAR_ATTR]: ClearIcon,
        [TOOL_TYPE.REMOVE_HOLE]: RemoveHoleIcon,
        [TOOL_TYPE.COPY_TOOL]: CopyToolIcon,
        [TOOL_TYPE.LINE_SNIPPING_TOOL]: LineSnippingIcon,
        [TOOL_TYPE.MEASURE_TOOL]: MeasureIcon,
        undo: UndoIcon,
        redo: RedoIcon
    };
    return <Icon style={{ fontSize: 23 }} component={icons[type]} />;
}

function getModeIcon(type) {
    const icons = {
        [SNIPPING_MODES.ONE_CLICK]: OneClickLineSnippingIcon,
        [SNIPPING_MODES.TWO_CLICK]: TwoClickLineSnippingIcon
    };
    return <Icon style={{ fontSize: 23 }} component={icons[type]} />;
}

export const ShortcutKeys = props => {
    let tools = TOOL_BTN;
    if (props.sourceType) {
        const sourceTools = SOURCE_TYPE_DATA[props.sourceType]?.tools ?? [];
        tools = TOOL_BTN.filter(tool => sourceTools.includes(tool.type));
    }

    return (
        <div className={'shortcut-keys-container'}>
            {tools.map((tool, i) => {
                return (
                    tool.shortcut_key && (
                        <span key={i} className={'shortcut-key'}>{`${tool.shortcut_key} (${tool.title})`}</span>
                    )
                );
            })}

            <span className={'shortcut-key'}>{`ctrl+z (Undo)`}</span>
            <span className={'shortcut-key'}>{`ctrl+y (Redo)`}</span>
        </div>
    );
};

export const MultiModeToolComponent = props => {
    const { disabled, options, title, icon, className, defaultValue } = props;
    const [item, setItem] = useState(options[0]);

    const onClick = id => {
        const _item = options.find(i => i.id === id);
        setItem(_item);
        props.onChange(id);
    };

    const menu = (
        <Menu onClick={e => onClick(e.key)}>
            {options.map(o => (
                <Menu.Item key={o.id} icon={o.icon}>
                    {o.title}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown.Button
            overlay={menu}
            size='large'
            icon={<DownOutlined />}
            defaultValue={defaultValue}
            disabled={disabled}
            className={className}
            placement={'bottomCenter'}
            onClick={_ => item && props.onChange(item.id)}
            buttonsRender={([leftButton, rightButton]) => [
                <Tooltip title={title} key='leftButton'>
                    {leftButton}
                </Tooltip>,
                React.cloneElement(rightButton, { className: 'icon-only-btn' })
            ]}>
            {item.icon}
        </Dropdown.Button>
    );
};
