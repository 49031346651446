import DrawHole from 'ol-ext/interaction/DrawHole';
import { selectStyle } from '../MapBase';
import { undoRedoPush } from '../MapInit';

class DrawHoles {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.hole = null;
    }

    init(id) {
        this.off();
        const layer = this.mapObj.map.getLayerById(id);
        this.hole = new DrawHole({
            layers: [layer],
            style: selectStyle(),
            dragVertexDelay: 0,
            snapTolerance: 1,
            condition: e => {
                const mouseClick = e.originalEvent.which;
                if (mouseClick == 3 || mouseClick == 2) {
                    return false;
                }
                return true;
            }
        });
        this.mapObj.map.addInteraction(this.hole);
        this.hole.on('drawend', undoRedoPush);
        window.addEventListener('keydown', this.keyDownHandler);
    }

    keyDownHandler = e => {
        if (e.code == 'Backspace') {
            this.hole.removeLastPoint();
        } else if (e.code == 'Space') {
            this.hole.finishDrawing();
        }
    };

    off() {
        this.hole && this.mapObj.map.removeInteraction(this.hole);
        this.hole && this.hole.un('drawend', undoRedoPush);
        window.removeEventListener('keydown', this.keyDownHandler);
    }
}

export default DrawHoles;
