import Split from 'ol-ext/interaction/Split';
import { undoRedoPush } from '../MapInit';
import turfDistance from '@turf/distance';
import { point } from '@turf/helpers';
import { toLonLat } from 'ol/proj';

export const SNIPPING_MODES = {
    ONE_CLICK: 'one-click-snipping',
    TWO_CLICK: 'two-click-snipping'
};

const DEFAULT_MODE = SNIPPING_MODES.ONE_CLICK;
const DISTANCE_FACTOR = 1e-2;

class LineSnippingTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.split = null;
        this.mode = DEFAULT_MODE;
        this.transactionComplete = true;
    }

    init(id, mode = DEFAULT_MODE) {
        this.off();
        this.mode = mode;
        this.layer = this.mapObj.map.getLayerById(id);
        if (this.layer) {
            const splitOptions = { sources: this.layer.getSource(), snapDistance: 25, tolerance: 1e-7 };
            if (this.mode === SNIPPING_MODES.TWO_CLICK) {
                splitOptions.filter = this.filterFn;
            }
            this.split = new Split(splitOptions);
            this.mapObj.map.addInteraction(this.split);
            this.split.on('aftersplit', this.afterSplit);
            if (this.mode === SNIPPING_MODES.TWO_CLICK) {
                this.resetValues();
                this.split.on('beforesplit', this.beforeSplit);
                this.split.on('pointermove', this.handlePointerMove);
            }
            window.addEventListener('keydown', this.handleKeyDawn);
        }
    }

    off() {
        if (!this.transactionComplete) {
            this.backToInitialStage();
        } else {
            this.resetValues();
        }
        this.split && this.mapObj.map.removeInteraction(this.split);
        this.split && this.split.un('aftersplit', this.afterSplit);
        this.split && this.split.un('beforesplit', this.beforeSplit);
        this.split && this.split.un('pointermove', this.handlePointerMove);
        window.removeEventListener('keydown', this.handleKeyDawn);
    }

    handlePointerMove = e => {
        this.event = e;
    };

    beforeSplit = e => {
        this.transactionComplete = false;
        if (!this.firstPoint) {
            this.firstPoint = this.event.coordinate;
        } else if (!this.lastPoint) {
            this.lastPoint = this.event.coordinate;
        }
    };

    afterSplit = e => {
        if (this.mode === SNIPPING_MODES.TWO_CLICK) {
            if (this.firstPoint && this.lastPoint) {
                const feature = this.getRemovableFeature(e.features);
                feature && this.layer.getSource().removeFeature(feature);
                this.resetValues();
                undoRedoPush();
            } else {
                this.features = e.features;
                this.original = e.original;
            }
        } else {
            const feature = this.getRemovableFeature(e.features);
            feature && this.layer.getSource().removeFeature(feature);
            this.transactionComplete = true;
            undoRedoPush();
        }
    };

    handleKeyDawn = e => {
        if (e.key == 'Backspace' && !this.transactionComplete) {
            this.backToInitialStage();
        }
    };

    filterFn = f => {
        if (this.features) {
            return this.features.some(ft => f === ft);
        }
        return true;
    };

    backToInitialStage = () => {
        if (this.original && this.features) {
            const source = this.layer.getSource();
            this.features.forEach(f => {
                source.removeFeature(f);
            });
            source.addFeature(this.original);
            this.resetValues();
        }
    };

    getDistanceFactor(d) {
        const view = this.mapObj.map.getView();
        const zoom = parseInt(view.getZoom());

        let distanceFactor = DISTANCE_FACTOR;
        if (zoom > 16) {
            distanceFactor = 1e-3;
        } else if (zoom < 10) {
            distanceFactor = 1;
        } else {
            distanceFactor = 1e-1; //Math.pow(10, -zoom % 10);
        }
        return distanceFactor;
    }

    getCurrentMode = () => {
        return this.mode;
    };

    resetValues = () => {
        this.firstPoint = null;
        this.lastPoint = null;
        this.transactionComplete = true;
        this.features = null;
        this.original = null;
    };

    isClosestPoints = (p1, p2) => {
        const _p1 = toLonLat(p1);
        const _p2 = toLonLat(p2);
        const distance = turfDistance(point(_p1), point(_p2));
        const distanceFactor = this.getDistanceFactor(distance);
        return distance < distanceFactor;
    };

    getDistance = (p1, p2) => {
        const _p1 = toLonLat(p1);
        const _p2 = toLonLat(p2);
        const distance = turfDistance(point(_p1), point(_p2));
        return distance;
    };

    getRemovableFeature = features => {
        const geom0 = features[0].getGeometry();
        const geom1 = features[1].getGeometry();

        let found = 0;
        if (this.mode === SNIPPING_MODES.TWO_CLICK) {
            if (this.firstPoint && this.lastPoint) {
                const geom0fp = geom0.getFirstCoordinate();
                const geom0lp = geom0.getLastCoordinate();
                const geom1fp = geom1.getFirstCoordinate();
                const geom1lp = geom1.getLastCoordinate();

                const [fp_g0fp, fp_g1fp, fp_g0lp, fp_g1lp] = [
                    this.getDistance(this.firstPoint, geom0fp),
                    this.getDistance(this.firstPoint, geom1fp),
                    this.getDistance(this.firstPoint, geom0lp),
                    this.getDistance(this.firstPoint, geom1lp)
                ];

                if (fp_g0fp < fp_g1lp || fp_g0lp < fp_g1fp) {
                } else {
                    found = 1;
                }

                // [geom0, geom1].forEach((g, idx) => {
                //     const lineFP = g.getFirstCoordinate();
                //     const lineLP = g.getLastCoordinate();
                //     // if (
                //     //     (this.isClosestPoints(this.firstPoint, lineFP) &&
                //     //         this.isClosestPoints(this.lastPoint, lineLP)) ||
                //     //     (this.isClosestPoints(this.firstPoint, lineLP) && this.isClosestPoints(this.lastPoint, lineFP))
                //     // ) {
                //     //     found = idx;
                //     // }
                // });

                return features[found];
            }
        } else {
            const length_0 = geom0.getLength();
            const length_1 = geom1.getLength();
            found = length_1 < length_0 ? 1 : 0;
            return features[found];
        }
    };
}

export default LineSnippingTool;
