/**
 * Message constants
 */
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const ERROR = 'ERROR';
export const ERROR_MESSAGE_404 = 'URL Not Found.';
export const ERROR_MESSAGE_500 = 'Internal Server Error.';
export const ERROR_SOMETHING_WRONG = 'Opps! Something went wrong.';
export const COPY_TO_CLIPBOARD_SUCCESS = 'Successfully copied!!';
export const COPY_TO_CLIPBOARD_FAILED = 'Unable to copy on clipboard';
export const ERROR_COPY_NOT_SUPPORTED = 'Copy not supported on your browser';
export const ERROR_INVALID_FEATURES = 'Invalid features. Please delete and redraw highlighted features';
export const ERROR_INVALID_FEATURE = 'Invalid feature. Please delete and redraw highlighted feature';
export const ERROR_CODES = {
    1: 'Test'
};
