import { Select, DragBox } from 'ol/interaction';
import { selectStyle } from '../MapBase';
import { message } from 'antd';
import { click } from 'ol/events/condition';
import { unByKey } from 'ol/Observable';
import { undoRedoPush } from '../MapInit';
import GeoJSON from 'ol/format/GeoJSON';
import clone from '@turf/clone';
import union from '@turf/union';
import { LAWN_ATTRIBUTE, LAYER_TYPE_GEOMETRY_MAP, PARCEL, TOOL_TYPE } from '../../../../Constants/MapConstant';
import { polygon, featureCollection } from '@turf/turf';
import Feature from 'ol/Feature';
import { captureException, setExtra } from '@sentry/react';

const LAWN_KEY_MAP = {
    1: 'F',
    2: 'B',
    3: 'L',
    4: 'R'
};

class SelectTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.select = null;
        this.drag = null;
        this.layer = null;
        this.key = null;
        this.clipboard = [];
    }

    init(id) {
        this.off();
        this.layer = this.mapObj.map.getLayerById(id);

        if (this.layer) {
            this.select = new Select({
                layers: [this.layer],
                multi: true,
                condition: click,
                toggleCondition: click,
                style: selectStyle(id)
            });
            this.mapObj.map.addInteraction(this.select);

            this.drag = new DragBox();
            this.mapObj.map.addInteraction(this.drag);

            this.drag.on('boxdrag', () => {
                this.emptyFeatureArray();
            });

            this.drag.on('boxend', () => {
                let extent = this.drag.getGeometry().getExtent();
                this.layer.getSource().forEachFeatureIntersectingExtent(extent, feature => {
                    this.select.getFeatures().push(feature);
                });
            });

            this.key = this.mapObj.map.on('singleclick', e => {
                let layer = this.mapObj.map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                    return layer;
                });
                if (!layer) {
                    this.emptyFeatureArray();
                } else if (layer.get('id') == PARCEL) {
                    this.emptyFeatureArray();
                }
            });

            window.addEventListener('keydown', this.handleKeydown, false);
        }
    }

    handleKeydown = evt => {
        const key = evt.keyCode;
        if (key == 46 || evt.key === 'd') {
            this.deleteFeatures();
        } else if (evt.key === 'c') {
            this.mergeFeatures();
        } else if ([1, 2, 3, 4].includes(parseInt(evt.key))) {
            this.changeLawnAttribute(LAWN_KEY_MAP[evt.key]);
        }
    };
    cut() {
        this.clipboard = [];
        this.is_cut = true;
        this.prev_layer = this.layer;
        if (this.select) {
            this.select
                .getFeatures()
                .getArray()
                .forEach(feat => {
                    this.clipboard.push(feat);
                });
        }
    }
    copy() {
        this.clipboard = [];
        this.is_cut = false;
        if (this.select) {
            this.select
                .getFeatures()
                .getArray()
                .forEach(feat => {
                    this.clipboard.push(feat);
                });
        }
    }
    paste(id) {
        const layer = this.mapObj.map.getLayerById(id);
        if (layer) {
            if (this.clipboard.length > 0) {
                const layer_geometry_type = LAYER_TYPE_GEOMETRY_MAP[id];
                const copied_geometry_type = this.clipboard[0].getGeometry().getType(); // Assuming all are of same type
                // Paste only if geometry types match
                if (layer_geometry_type == copied_geometry_type) {
                    const source = layer.getSource();
                    this.clipboard.forEach(feat => {
                        let geom = feat.getGeometry().clone();
                        // Translate features only if copying
                        if (!this.is_cut) {
                            geom.translate(5, 5);
                        }
                        let new_feat = new Feature({ geometry: geom });
                        source.addFeature(new_feat);
                        if (this.is_cut) {
                            this.prev_layer && this.prev_layer.getSource().removeFeature(feat);
                            this.clipboard = [];
                        }
                    });
                    this.select.getFeatures().clear();
                    undoRedoPush();
                } else {
                    message.error(`Trying to paste ${copied_geometry_type} features into ${layer_geometry_type} layer`);
                }
            }
        }
    }
    deleteFeatures() {
        const features = this.select ? this.select.getFeatures().getArray() : [];
        if (!features.length) {
            message.error('No feature is selected.');
        } else {
            let source = this.layer.getSource();
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                source.removeFeature(feature);
            }
            this.emptyFeatureArray();
            undoRedoPush();
        }
    }

    mergeFeatures() {
        const features = this.select ? this.select.getFeatures().getArray() : [];
        if (!features.length) {
            message.error('No feature is selected.');
        } else {
            const lawnType = features[0].getProperties()[LAWN_ATTRIBUTE];

            let geojson = new GeoJSON().writeFeaturesObject(features, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            });

            let source = this.layer.getSource();
            let merged = turfMerge(geojson);

            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                source.removeFeature(feature);
            }

            let mergedGeojson = new GeoJSON().readFeatures(merged, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            });

            mergedGeojson.forEach(f => {
                f.setProperties({ [LAWN_ATTRIBUTE]: lawnType });
            });

            source.addFeatures(mergedGeojson);

            this.emptyFeatureArray();
            undoRedoPush();
        }
    }

    changeLawnAttribute(lawnType) {
        const features = this.select ? this.select.getFeatures().getArray() : [];
        if (!features.length) {
            message.error('No feature is selected.');
        } else {
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                feature.setProperties({ [LAWN_ATTRIBUTE]: lawnType });
            }
            this.emptyFeatureArray();
            undoRedoPush();
        }
    }

    emptyFeatureArray() {
        if (this.select) {
            this.select.getFeatures().clear();
        }
    }

    off() {
        if (this.mapObj?.AppStore?.current_tool !== TOOL_TYPE.SELECT) {
            this.clipboard = [];
        }
        this.emptyFeatureArray();
        this.mapObj.map.removeInteraction(this.select);
        this.mapObj.map.removeInteraction(this.drag);
        unByKey(this.key);
        window.removeEventListener('keydown', this.handleKeydown);
    }
}

export default SelectTool;

export function turfMerge(fc) {
    let merged = clone(fc.features[0]);
    let poly;
    let features = fc.features;
    // Storing unmerged polygons (due to TopologyException) and adding them separately
    let unmerged = [];
    try {
        for (let i = 0, len = features.length; i < len; i++) {
            poly = features[i];
            if (poly.geometry) {
                try {
                    merged = union(merged, poly);
                } catch (err) {
                    if (err.name == 'TopologyException') {
                        unmerged.push(poly);
                    }
                    setExtra('merged', JSON.stringify(merged));
                    setExtra('poly', JSON.stringify(poly));
                    setExtra('features', features);
                    setExtra('Request ID', localStorage.getItem('job_id'));
                }
            }
        }
        let polys = [];
        let _features;
        // union returns multipolygon when no feature is overlapped
        // convert multipolygon to polygon
        if (merged.geometry.type == 'MultiPolygon') {
            merged.geometry.coordinates.forEach(function (coords) {
                let poly = polygon(coords);
                polys.push(poly);
            });
            // Pushing unmerged polygons into the list
            unmerged.forEach(poly => {
                polys.push(poly);
            });
            _features = featureCollection(polys);
        } else {
            // If there are any unmerged polygons, return a featureCollection containing unmerged polygons
            if (unmerged.length > 0) {
                let polys = [];
                unmerged.forEach(poly => {
                    polys.push(poly);
                });
                polys.push(merged);
                _features = featureCollection(polys);
            } else {
                _features = merged;
            }
        }
        return _features;
    } catch (err) {
        setExtra('merged', JSON.stringify(merged));
        setExtra('poly', JSON.stringify(poly));
        setExtra('features', features);
        setExtra('Request ID', localStorage.getItem('job_id'));
        captureException(err);
    }
}
