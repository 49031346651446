import Split from 'ol-ext/interaction/Split';
import { selectStyle } from '../MapBase';
import { undoRedoPush, undoRedoObj } from '../MapInit';
import { Stroke, Style, Fill, Circle } from 'ol/style';
import MultiPoint from 'ol/geom/MultiPoint';
import { LAYER_COLOR } from '../../../../Constants/MapConstant';
class SplitLine {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.split = null;
        this.current_style = null;
    }

    init(id) {
        this.off();
        // Style to visualize endpoints of line
        this.split_style = new Style({
            image: new Circle({
                radius: 4,
                stroke: new Stroke({
                    color: LAYER_COLOR[id] || 'blue'
                })
            }),
            geometry: function (feature) {
                var coordinates = feature.getGeometry().getCoordinates();
                let first_pt = coordinates[0];
                let last_pt = coordinates[coordinates.length - 1];
                return new MultiPoint([first_pt, last_pt]);
            }
        });

        this.layer = this.mapObj.map.getLayerById(id);
        if (this.layer) {
            this.current_style = this.layer.getStyle();
            if (this.current_style instanceof Function) {
                this.splitStyleFn = (feature, resolution) => {
                    const current_feat_style = this.current_style(feature, resolution);
                    return [current_feat_style, this.split_style];
                };
                this.layer.setStyle(this.splitStyleFn);
            } else {
                this.layer.setStyle([this.current_style, this.split_style]);
            }
            this.split = new Split({
                sources: this.layer.getSource()
            });
            this.mapObj.map.addInteraction(this.split);
            this.split.on('aftersplit', undoRedoPush);
            window.addEventListener('keydown', this.handleKeyDawn);
        }
    }

    handleKeyDawn = e => {
        if (e.key == 'Backspace') {
            undoRedoObj.undo();
        }
    };

    off() {
        this.split && this.mapObj.map.removeInteraction(this.split);
        this.split && this.split.un('aftersplit', undoRedoPush);
        window.removeEventListener('keydown', this.handleKeyDawn);
        this.current_style && this.layer.setStyle(this.current_style);
        this.current_style = null;
    }
}

export default SplitLine;
