let BASE_SERVER_URL;
if (process.env.REACT_APP_ENV == 'stage') {
    BASE_SERVER_URL = 'https://qcws-back-stage.attentive.ai';
} else if (process.env.REACT_APP_ENV == 'dev') {
    BASE_SERVER_URL = 'https://qcws-back-dev.attentive.ai';
} else if (process.env.REACT_APP_ENV == 'prod') {
    BASE_SERVER_URL = 'https://qcws-back.attentive.ai';
} else {
    BASE_SERVER_URL = 'https://qcws-back-stage.attentive.ai';
    // BASE_SERVER_URL = 'http://localhost:8000';
}
export const BASE_SERVER = BASE_SERVER_URL;

/**
 * Annotation Page
 */
export const BASE_URL = BASE_SERVER + '/api/';
export const LOGIN = 'auth/login/';
export const GET_JOB = 'active_work_items/';
export const PING = 'ping/';
export const SUBMIT_JOB = 'active_work_items/%s/submit/';
export const SAVE_JOB = 'active_work_items/%s/save/';
export const UPDATE_META_DATA = 'active_work_items/%s/set_meta_data/';
export const GET_LEADERBOARD = 'ratings/leaderboard/';

/**
 * Rating Pages
 */
export const GET_LAYERS = 'layer_types/';
export const GET_QC_RATINGS = 'ratings/qc/';
export const GET_QA_RATINGS = 'ratings/qa/';
export const GET_QA_RATINGS_FOR_ADMIN = 'falcon/qa_ratings/';
export const RATE_QA = 'ratings/rate_qa/';

/**
 * Jobs
 */
export const GET_JOBS = 'monitoring/job_groups/?source_type=';
export const GET_SOURCES = 'monitoring/source_type/';
export const GET_USERS = 'monitoring/users/all/?source_type=';
export const GET_MONITORING_USERS = 'monitoring/users/?source_type=';

/**
 * Management
 */
export const LIST_USERS = 'users/';
export const CREATE_USER = 'auth/create_user/';
export const DISABLE_USER = 'auth/disable/';
export const ENABLE_USER = 'auth/enable/';
export const SOURCE_TYPE = 'monitoring/source_type/';
export const SOURCE_TYPE_SINGLE = 'monitoring/source_type/%s/';
export const LAYER_TYPE = 'monitoring/layer_type/';
export const LAYER_TYPE_SINGLE = 'monitoring/layer_type/%s/';
export const LAYER_GROUP = 'monitoring/layer_group/';
export const LAYER_GROUP_SINGLE = 'monitoring/layer_group/%s/';
export const TEAM = 'monitoring/team/';
export const TEAM_SINGLE = 'monitoring/team/%s/';

// data platform
export const REQUESTS = 'falcon/request/';
export const REQUEST_DETAILS = 'falcon/request/?request_id=%s';
export const UPLOAD = 'falcon/upload/?request_id=%s';
export const DOWNLOAD = 'falcon/download/?request_id=%s';
export const ETA_UPDATE = 'falcon/eta/';
