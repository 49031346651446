import { Draw } from 'ol/interaction';
import { drawStyle } from '../MapBase';
import { undoRedoPush } from '../MapInit';

class DrawPoint {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.snap = null;
    }

    init(id) {
        this.off();

        const layer = this.mapObj.map.getLayerById(id);
        this.layer = layer;
        if (layer) {
            const source = layer.getSource();

            this.draw = new Draw({
                source: source,
                type: 'Point',
                style: drawStyle(id),
                dragVertexDelay: 0,
                snapTolerance: 1,
                condition: e => {
                    const mouseClick = e.originalEvent.which;
                    if (mouseClick == 3 || mouseClick == 2) {
                        return false;
                    }
                    return true;
                }
            });
            this.mapObj.map.addInteraction(this.draw);
            this.snap = this.mapObj.getSnap();
            this.mapObj.map.addInteraction(this.snap);
            this.draw.on('drawend', this.pushTimeout);
            window.addEventListener('keydown', this.keyDownHandler);
            // this.layer.getSource().on('addfeature', undoRedoPush);
        }
    }
    keyDownHandler = e => {
        if (e.key == 'Backspace') {
            this.draw.removeLastPoint();
        }
    };

    pushTimeout() {
        setTimeout(() => {
            undoRedoPush();
        }, 0);
    }

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.mapObj.map.removeInteraction(this.snap);
        this.draw && this.draw.un('drawend', this.pushTimeout);
        window.removeEventListener('keydown', this.keyDownHandler);
        // this.layer && this.layer.getSource().un('addfeature', undoRedoPush);
    }
}

export default DrawPoint;
