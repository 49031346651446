import { captureException } from '@sentry/minimal';
import { SOURCE_TYPE_LAYERS_MAP } from '../../../../Constants/MapConstant';

class RightClickTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.hitTolerance = 10;
        this.layers = [];
        this.ignoreLayers = [];
    }

    init() {
        const availableLayers = this.getAvailableLayers();
        const togglableLayers = this.getTogglableLayers();
        this.layers = togglableLayers.filter(layer => availableLayers.includes(layer));
        this.ignoreLayers = availableLayers.filter(al => !this.layers.includes(al));
        this.mapObj.map.getViewport().addEventListener('contextmenu', this.toggleLayers);
    }

    off() {
        this.layers = [];
        this.ignoreLayers = [];
        this.mapObj.map.getViewport().removeEventListener('contextmenu', this.toggleLayers);
    }

    getAvailableLayers = () => {
        return this.mapObj.map
            .getLayers()
            .getArray()
            .map(l => l.get('id'));
    };

    getTogglableLayers = () => {
        return SOURCE_TYPE_LAYERS_MAP[this.mapObj.AppStore.current_source_type] || [];
    };

    getLayer = id => {
        return this.mapObj.map.getLayerById(id);
    };

    getOnPixelData = px => {
        return (
            !this.isOverlappedFeatures(px) &&
            this.mapObj.map.forEachFeatureAtPixel(
                px,
                (feature, layer) => {
                    return { feature, layer };
                },
                { layerFilter: this.layerFilter, hitTolerance: this.hitTolerance }
            )
        );
    };

    layerFilter = layer => {
        const id = layer.get('id');
        return !this.ignoreLayers.includes(id);
    };

    isOverlappedFeatures = px => {
        return this.mapObj.map.getFeaturesAtPixel(px, { layerFilter: this.layerFilter }).length > 1;
    };

    getNextLayer = currentLayer => {
        let currentLayerPos = this.layers.indexOf(currentLayer);
        if (currentLayerPos !== -1) {
            currentLayerPos = (currentLayerPos + 1) % this.layers.length;
            return this.getLayer(this.layers[currentLayerPos]);
        }
    };

    toggleLayers = e => {
        const pixel = this.mapObj.map.getEventPixel(e);
        const { feature, layer } = this.getOnPixelData(pixel) || {};
        if (layer && feature) {
            const toLayer = this.getNextLayer(layer.get('id'));
            if (toLayer) {
                try {
                    layer.getSource().removeFeature(feature);
                    toLayer.getSource().addFeature(feature);
                } catch (e) {
                    captureException(e);
                }
            }
        }
    };
}

export default RightClickTool;
