import { Modify } from 'ol/interaction';
import { highlightFeatureIfInvalid } from '../../../../Utils/HelperFunctions';
import { drawStyle } from '../MapBase';
import { undoRedoPush } from '../MapInit';

class ModifyEdge {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.modify = null;
    }

    init(id) {
        this.off();

        this.layer = this.mapObj.map.getLayerById(id);
        if (this.layer) {
            const source = this.layer.getSource();

            this.modify = new Modify({
                source: source,
                style: drawStyle(id),
                pixelTolerance: 12
            });
            this.mapObj.map.addInteraction(this.modify);
            this.modify.on('modifyend', this.onModifyEnd);
        }
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    off() {
        this.mapObj.map.removeInteraction(this.modify);
        this.modify && this.modify.un('modifyend', this.onModifyEnd);

        window.removeEventListener('keydown', this.handleKeydown);
    }

    handleKeydown = evt => {
        const key = evt.keyCode;
        if (key == 46) {
            this.deleteVertex();
        }
    };

    onModifyEnd = e => {
        const feature = e.features.getArray()[0];
        highlightFeatureIfInvalid(feature);
        undoRedoPush();
    };

    deleteVertex() {
        this.modify.removePoint();
    }
}

export default ModifyEdge;
