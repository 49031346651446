import { containsCoordinate } from 'ol/extent';
import { unByKey } from 'ol/Observable';
import { PARCEL } from '../../../../Constants/MapConstant';
import { undoRedoPush } from '../MapInit';

class RemoveHole {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.click = null;
    }

    init(id) {
        const layer = this.mapObj.map.getLayerById(id);
        if (layer) {
            this.mapObj.map.getViewport().style.cursor = 'crosshair';

            const source = layer.getSource();
            this.click = this.mapObj.map.on('singleclick', e => {
                let _l = this.mapObj.map.forEachFeatureAtPixel(e.pixel, function (f, layer) {
                    return layer;
                });

                if (_l && _l.get('id') != PARCEL) {
                    return;
                }

                let coord = e.coordinate;
                let selected_feature;
                source.getFeatures().forEach(feature => {
                    const extent = feature.getGeometry().getExtent();
                    const contains = containsCoordinate(extent, coord);
                    if (contains) {
                        selected_feature = feature;
                        return;
                    }
                });
                if (selected_feature) {
                    let poly_geom = selected_feature.getGeometry();
                    let rings = poly_geom.getLinearRings();
                    let poly_coord_no_ring = [rings[0].getCoordinates()];

                    rings.forEach((ring, index) => {
                        const ring_coord = ring.getCoordinates();
                        const ring_extent = ring.getExtent();
                        const ring_at_pointer = containsCoordinate(ring_extent, coord);
                        if (!ring_at_pointer && index != 0) {
                            poly_coord_no_ring.push(ring_coord);
                        }
                    });
                    poly_geom.setCoordinates(poly_coord_no_ring);
                    undoRedoPush();
                }
            });
        }
    }

    off() {
        this.mapObj.map.getViewport().style.cursor = '';
        unByKey(this.click);
    }
}

export default RemoveHole;
