import 'ol/ol.css';
import Map from 'ol/Map';
import { asArray } from 'ol/color/';
import Tile from 'ol/layer/Tile';
import TileImage from 'ol/source/TileImage';
import Static from 'ol/source/ImageStatic';
import ImageLayer from 'ol/layer/Image';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import GeoJSON from 'ol/format/GeoJSON';
import { Stroke, Style, Fill, Circle } from 'ol/style';
import { fromLonLat, toLonLat, transform, transformExtent } from 'ol/proj';
import { defaults as defaultControls, Zoom } from 'ol/control';
import { defaults as defaultInteractions, Snap } from 'ol/interaction';
import {
    LAYER_COLOR,
    LAYER_NAME,
    LAWN_ATTRIBUTE,
    EMPTY_GEOJSON,
    StyleGen,
    GEO_JSON,
    PARCEL,
    LAYER_TYPE_GEOMETRY_MAP,
    GEOMETRY_TYPES,
    TOOL_TYPE,
    SOURCE_TYPE,
    LAYER_ZINDEX,
    ModifiedStyleGenFn,
    LAYER
} from '../../../Constants/MapConstant';
import { undoRedoObj, undoRedoPush } from './MapInit';
import { buffer } from 'ol/extent';
import Collection from 'ol/Collection';
import { turfMerge } from './Tools/SelectTool';
import DragPan from 'ol/interaction/DragPan';
import KeyboardPan from 'ol/interaction/KeyboardPan';
import KeyboardZoom from 'ol/interaction/KeyboardZoom';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import GeoImageSource from 'ol-ext/source/GeoImage';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import transformRotate from '@turf/transform-rotate';
import turfDifference from '@turf/difference';
import { getCoords } from '@turf/invariant';
import intersect from '@turf/intersect';
import { polygon as turfPolygon } from '@turf/helpers';
import { captureException, setExtra } from '@sentry/react';
import { highlightFeature, isInvalidPoly } from '../../../Utils/HelperFunctions';

const GOOGLE_IMAGERY_SATELLITE = 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}';
const DRAW_WIDTH = 3;
const ZOOM = 5;

class MapBase {
    constructor() {
        this.map = null;
        this.base_layer = null;
        this.google_layer = null;
        this.draw = null;
        this.snap = null;
        this.modify = null;
    }

    getInteractions() {
        return [
            new DragPan({
                condition: e => {
                    if (e.originalEvent.which == 2 || this.AppStore?.current_tool == TOOL_TYPE.PAN) {
                        return true;
                    }
                    return false;
                }
            }),
            new KeyboardPan({ duration: 10 }),
            new KeyboardZoom({ duration: 10 }),
            new MouseWheelZoom({ duration: 10, maxDelta: 32, timeout: 10 })
        ];
    }

    init(AppStore) {
        this.AppStore = AppStore;
        const interactions = new Collection(this.getInteractions());
        this.map = new Map({
            controls: defaultControls(),
            interactions: interactions,
            target: 'map',
            layers: [],
            view: new View({
                center: fromLonLat([-104, 39]),
                zoom: ZOOM,
                minZoom: 2,
                maxZoom: 22,
                extent: transformExtent([-180, -90, 180, 90], 'EPSG:4326', 'EPSG:3857')
            }),
            keyboardEventTarget: document
        });
        this.oblique_images = {};
        this.ortho_image = {};
        Map.prototype.getLayerById = function (id) {
            return this.getLayers()
                .getArray()
                .find(lyr => id == lyr.get('id'));
        };

        this.map.on('contextmenu', e => {
            e.preventDefault();
            this.AppStore.ContextMenuFn(this, e);
        });

        this.addBaseLayer();
        this.addGoogleBaseLayer();

        return this.map;
    }

    addBaseLayer() {
        let source = new OSM();
        this.base_layer = new Tile({
            source,
            id: 'base_layer',
            zIndex: 0
        });
        this.map.addLayer(this.base_layer);
    }

    addGoogleBaseLayer() {
        let source = new TileImage({
            url: GOOGLE_IMAGERY_SATELLITE
        });
        this.google_layer = new Tile({
            source,
            id: 'google_layer',
            zIndex: 0
        });
        this.google_layer.setVisible(false);
        this.map.addLayer(this.google_layer);
    }

    loadImageLayer(zoom = false) {
        if (!this.ortho_image) {
            console.log('Attempt to load non existing ortho layer');
            return;
        }
        const url = this.ortho_image.url;
        const _bounds = this.ortho_image.bounds;
        const _bx = transformExtent(
            [_bounds.left, _bounds.bottom, _bounds.right, _bounds.top],
            'EPSG:4326',
            'EPSG:3857'
        );
        const img_src = new Static({
            url: url,
            imageExtent: _bx
        });
        const img_layer = new ImageLayer({
            id: 'image_static_ORTHO',
            source: img_src,
            zIndex: 1
        });

        this.map.addLayer(img_layer);
        if (zoom) {
            this.zoomToExtent(_bx);
        }
        this.AppStore.setOrthoImageVisible(true);
    }
    addImageLayer(url, _bounds = {}) {
        this.ortho_image.url = url;
        this.ortho_image.bounds = _bounds;
        this.loadImageLayer(true);
    }
    addObliqueImageLayer(url, _orientation, _bounds = {}) {
        if (_orientation == 'WEST' || _orientation == 'EAST') {
            const boundary_layer_bbox = [_bounds.left, _bounds.bottom, _bounds.right, _bounds.top];
            const boundary_layer_bbox_polygon = bboxPolygon(boundary_layer_bbox);
            const rotatedPoly = transformRotate(boundary_layer_bbox_polygon, 270);
            const new_bounds = bbox(rotatedPoly);
            _bounds.left = new_bounds[0];
            _bounds.bottom = new_bounds[1];
            _bounds.right = new_bounds[2];
            _bounds.top = new_bounds[3];
        }
        this.oblique_images[_orientation] = {
            url: url,
            bounds: { ..._bounds }
        };
    }
    loadObliqueImageryLayer(orientation) {
        if (!this.oblique_images[orientation]) {
            console.log(`Attempt to load non existing orientation - ${orientation}`);
            return;
        }
        const url = this.oblique_images[orientation].url;
        const _bounds = this.oblique_images[orientation].bounds;
        const imageLoaded = () => {
            let _bx = transformExtent(
                [_bounds.left, _bounds.bottom, _bounds.right, _bounds.top],
                'EPSG:4326',
                'EPSG:3857'
            );
            const imageCenter = [(_bx[0] + _bx[2]) / 2, (_bx[1] + _bx[3]) / 2];
            const scale_x = (_bx[2] - _bx[0]) / img.width;
            const scale_y = (_bx[3] - _bx[1]) / img.height;
            // Using GeoImageSource instead of ImageStatic as we need rotation support
            const img_src = new GeoImageSource({
                url: url,
                imageCenter: imageCenter,
                imageScale: [scale_x, scale_y],
                imageExtent: _bx,
                // imageCrop: _bx,
                projection: 'EPSG:3857',
                imageRotate: rot
            });
            const img_layer = new ImageLayer({
                id: `image_static_${orientation}`,
                source: img_src,
                zIndex: 0
            });

            this.map.addLayer(img_layer);
        };

        var img = document.createElement('img');
        // Create image layer after loading image in an HTML element
        // This is done to obtain height, width of image before adding to map
        img.onload = imageLoaded;
        img.src = url;
        // Calculate rotation based on image orientation
        if (orientation == 'NORTH') {
            var rot_deg = 0;
        } else if (orientation == 'SOUTH') {
            var rot_deg = 180;
        } else if (orientation == 'EAST') {
            var rot_deg = 90;
        } else if (orientation == 'WEST') {
            var rot_deg = 270;
        }
        var rot = rot_deg * (Math.PI / 180);
    }
    moveImageryLayerToTop(orientation) {
        const layers = this.map.getLayers();
        layers.forEach(layer => {
            if (layer) {
                const layer_id = layer.get('id');
                if (layer_id.includes('image_static')) {
                    this.map.removeLayer(layer);
                }
            }
        });
        if (orientation == 'ORTHO') {
            this.loadImageLayer();
        } else {
            this.loadObliqueImageryLayer(orientation);
        }
    }
    addVectorLayer(json, id = LAYER_NAME, style = null) {
        let _source = new VectorSource({
            features: new GeoJSON().readFeatures(json, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            })
        });
        if (!style) {
            style = ModifiedStyleGenFn(id); //StyleGen(LAYER_COLOR[id]);
        }
        let _layer = new VectorLayer({
            id: id,
            source: _source,
            style: style,
            zIndex: LAYER_ZINDEX[id] ?? 100
        });

        this.map.addLayer(_layer);

        // undoRedoObj.init(id);
        return _source;
    }

    isIgnorableLayer = l => {
        const id = l.get('id');
        return (
            !(l instanceof VectorLayer) ||
            LAYER_TYPE_GEOMETRY_MAP[id] != GEOMETRY_TYPES.POLYGON ||
            id === PARCEL ||
            id === LAYER.PARCEL
        );
    };

    setLayersOpacity(opacity) {
        const layers = this.map.getLayers().getArray();
        for (const l of layers) {
            if (this.isIgnorableLayer(l)) {
                continue;
            }
            this.setOpacity(l.get('id'), opacity);
        }
    }

    setOpacity(id, value) {
        const layer = this.map.getLayerById(id);
        if (layer) {
            const style = layer.getStyle();
            const hexColor = style.getStroke().getColor();
            let color = asArray(hexColor);
            color = color.slice();
            color[3] = value;
            const fill = style.getFill();
            fill.setColor(color);
            layer.setStyle(style);
        }
    }

    addParcelLayer(url) {
        let source = this.addVectorLayer(url, PARCEL);
        const extent = source.getExtent();
        if (extent[0] !== Infinity) {
            const buffered = buffer(extent, 10);
            this.zoomToExtent(buffered);
        }
    }

    removeOutputLayers() {
        // TODO - Handle multiple layers in map
        const parcel_layer = this.map.getLayerById(PARCEL);
        this.map.removeLayer(parcel_layer);
        this.AppStore.current_layers.forEach(id => {
            const output_layer = this.map.getLayerById(id);
            this.map.removeLayer(output_layer);
        });
        // TODO: refactor to obtain orientations from system level constant
        const orientations = ['ORTHO', 'NORTH', 'SOUTH', 'EAST', 'WEST'];
        orientations.forEach(orientation => {
            const image_layer = this.map.getLayerById(`image_static_${orientation}`);
            if (image_layer) {
                this.map.removeLayer(image_layer);
            }
        });
        this.oblique_images = {};
        this.ortho_image = {};
    }

    getLayerGeoJson(id) {
        let parcel_layer = this.map.getLayerById(id);
        if (parcel_layer) {
            let feature = parcel_layer.getSource().getFeatures();
            if (feature.length) {
                let geojson = new GeoJSON();
                let parcel_geojson = geojson.writeFeatures(feature, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });
                return parcel_geojson && JSON.parse(parcel_geojson);
            }
        }
        return EMPTY_GEOJSON;
    }

    zoomToExtent(extent) {
        this.map.getView().fit(extent, { duration: 300, maxZoom: 21, size: this.map.getSize() });
    }

    restrictZoom() {
        this.map.getInteractions().forEach(interaction => {
            if (interaction instanceof MouseWheelZoom) {
                interaction.setActive(false);
            } else if (interaction instanceof KeyboardZoom) {
                interaction.setActive(false);
            }
        });

        this.map.getControls().forEach(control => {
            if (control instanceof Zoom) {
                this.map.removeControl(control);
            }
        });
    }

    restrictPan() {
        this.map.getInteractions().forEach(interaction => {
            if (interaction instanceof KeyboardPan) {
                interaction.setActive(false);
            } else if (interaction instanceof DragPan) {
                interaction.setActive(false);
            }
        });
    }

    resetMap() {
        this.map.addControl(new Zoom());
        this.map.getView().animate({ zoom: ZOOM, duration: 300 });
        this.getInteractions().forEach(interaction => {
            this.map.addInteraction(interaction);
        });
    }

    snapFeature(drawnFeature) {
        if (!drawnFeature) {
            return;
        }
        const layers = this.map.getLayers().getArray();
        for (const layer of layers) {
            const layerId = layer.get('id');
            if (layerId == PARCEL || LAYER_TYPE_GEOMETRY_MAP[layerId] != GEOMETRY_TYPES.POLYGON) {
                continue;
            }
            const sourcePoly = layer.getSource();
            const features = sourcePoly.getFeatures();

            for (let i = 0; i < features.length; ++i) {
                const existFeature = features[i];
                const existFeatureGeo = GEO_JSON.writeFeatureObject(existFeature);
                const existFeatureCoords = getCoords(existFeatureGeo);
                const drawnFeatureGeo = GEO_JSON.writeFeatureObject(drawnFeature); // Put inside so that every existFeature get updated drawn feature
                const drawnFeatureCoords = getCoords(drawnFeatureGeo);

                try {
                    const _existFeature = turfPolygon(existFeatureCoords);
                    const _drawnFeature = turfPolygon(drawnFeatureCoords);
                    const _intersect = intersect(_existFeature, _drawnFeature);
                    if (_intersect) {
                        const difference = turfDifference(_drawnFeature, _existFeature);
                        const newFeature = GEO_JSON.readFeature(rings);
                        drawnFeature.setGeometry(newFeature.getGeometry());
                    }
                } catch (err) {
                    const efcopy = Object.assign({}, existFeatureGeo);
                    const dfcopy = Object.assign({}, drawnFeatureGeo);
                    setExtra('existFeature', JSON.stringify(efcopy));
                    setExtra('drawnFeature', JSON.stringify(dfcopy));
                    setExtra('Request ID', localStorage.getItem('job_id'));
                    captureException(err);
                }
            }

            undoRedoPush(layer.get('id'));
        }
    }

    mergeAll() {
        let layer = this.map.getLayerById(this.AppStore.selected_layer_id);
        if (layer) {
            const src = layer.getSource();
            if (src) {
                const features = src.getFeatures();
                const geojson = new GeoJSON().writeFeaturesObject(features, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });

                const merged = turfMerge(geojson);
                let mergedGeojson = new GeoJSON().readFeatures(merged, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });

                mergedGeojson.forEach(f => {
                    f.setProperties({ [LAWN_ATTRIBUTE]: 'F' });
                });

                const new_source = new VectorSource({
                    features: mergedGeojson
                });
                layer.setSource(new_source);
                undoRedoPush();
            }
        }
    }

    deleteAll() {
        let layer = this.map.getLayerById(this.AppStore.selected_layer_id);
        if (layer) {
            const src = layer.getSource();
            const features = src.getFeatures();
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                src.removeFeature(feature);
            }
        }
    }

    getSnap(tolerance = 5) {
        const vl = this.map.getLayerById(this.AppStore.selected_layer_id);
        const pl = this.map.getLayerById(PARCEL);
        const snap = new Snap({
            features: new Collection(vl.getSource().getFeatures().concat(pl.getSource().getFeatures()), {
                unique: true
            }),
            pixelTolerance: tolerance
        });
        return snap;
    }

    setFeatureAttribute(type) {
        let layer = this.map.getLayerById(this.AppStore.selected_layer_id);
        if (layer) {
            const src = layer.getSource();
            const features = src.getFeatures();
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                feature.setProperties({ [LAWN_ATTRIBUTE]: type });
            }
        }
    }
}

MapBase.prototype.getEditableLayers = function () {
    return this.map
        .getLayers()
        .getArray()
        .filter(l => !this.isIgnorableLayer(l));
};

MapBase.prototype.getInvalidPolys = function () {
    const layers = this.getEditableLayers();
    const invalidPolys = [];
    for (const l of layers) {
        const features = l.getSource().getFeatures();
        for (const f of features) {
            if (isInvalidPoly(f)) {
                invalidPolys.push(f);
            }
        }
    }

    return invalidPolys;
};

export default MapBase;

export const drawStyle = id => {
    return new Style({
        stroke: new Stroke({
            color: LAYER_COLOR[id],
            width: DRAW_WIDTH
        }),
        image: new Circle({
            radius: 7,
            stroke: new Stroke({
                color: 'skyblue'
            })
        })
    });
};

export const selectStyle = () => {
    return new Style({
        stroke: new Stroke({
            color: 'orange',
            width: DRAW_WIDTH
        }),
        image: new Circle({
            radius: 7,
            stroke: new Stroke({
                color: 'skyblue'
            })
        }),
        fill: new Fill({
            color: 'rgba(255,255,255,0.2)'
        })
    });
};
