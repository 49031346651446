import React from 'react';
import { Button, Rate, Tag } from 'antd';
import { LAYER_COLOR } from '../../../Constants/MapConstant';
export const FALCON_QA_DEFAULT_META_DATA = { ratings: {} };
import { layer_colors_list } from './Falcon';
import Modal from 'antd/lib/modal/Modal';
export class FalconQAConfig extends React.Component {
    constructor(props) {
        super(props);
        this.AppStore = this.props.AppStore;
        this.state = {
            disabled: true,
            ratingModalTime: 0
        };
        const obj_clone = JSON.parse(JSON.stringify(FALCON_QA_DEFAULT_META_DATA));
        this.props.setMetaData(obj_clone);
    }
    ratingHandler = (layer, value) => {
        let current_meta_data = this.props.meta_data;
        current_meta_data.ratings[layer] = value;
        const obj_clone = JSON.parse(JSON.stringify(current_meta_data));
        this.props.setMetaData(obj_clone);

        if (this.AppStore.current_layers.length === Object.keys(current_meta_data.ratings).length) {
            this.setState({ disabled: false });
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.showModal && this.props.showModal != prevProps.showModal) {
            let auto_submit_timer = 60;
            this.rating_modal_timer && clearInterval(this.rating_modal_timer);
            this.rating_modal_timer = setInterval(() => {
                auto_submit_timer--;
                this.setState({ ratingModalTime: auto_submit_timer });

                if (this.state.ratingModalTime == 0) {
                    this.props.hideRatingModal();
                    this.props.handleSubmit();
                    this.rating_modal_timer && clearInterval(this.rating_modal_timer);
                }
            }, 1000);
        }
    }

    handleOk = () => {
        this.props.hideRatingModal();
        this.props.handleSubmit();
    };

    render() {
        const layers = this.AppStore.current_layers;

        const job_id_map = this.AppStore.current_layers.map(layer => {
            const layer_color = LAYER_COLOR[layer] || 'black';

            return (
                <div key={layer} className='text-center'>
                    <hr />
                    <div>
                        <svg className='legend-line'>
                            <line x1='0' y1='7' x2='20' y2='7' style={{ stroke: layer_color, strokeWidth: 4 }} />
                        </svg>
                        <span>{layer}</span>
                    </div>
                    <div>
                        <Rate
                            defaultValue={this.props.meta_data.ratings[layer] || 0}
                            onChange={value => {
                                this.ratingHandler(layer, value);
                            }}
                        />
                    </div>
                </div>
            );
        });
        return (
            <>
                <div className='falcon-qa-rate-panel'>
                    <div className='text-center'>
                        <b>Falcon QA</b>
                    </div>
                    <div>{job_id_map}</div>
                </div>
                {layers.indexOf('lawn_edge_layer') > -1 ? <div>{layer_colors_list}</div> : null}
                <Modal
                    title="Please rate the previous user's output"
                    visible={this.props.showModal}
                    maskClosable={false}
                    destroyOnClose={true}
                    closeIcon={<Tag color='red'>{this.state.ratingModalTime}</Tag>}
                    footer={[
                        <Button key='submit' type='primary' onClick={this.handleOk} disabled={this.state.disabled}>
                            Submit
                        </Button>
                    ]}>
                    <div>{job_id_map}</div>
                </Modal>
            </>
        );
    }
}
