import React, { Component, lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Skeleton } from 'antd';
import LoginPage from './LoginPage/LoginPage.js';

const MainPage = lazy(() => import('./MainPage/MainPage.js'));

@inject('AppStore')
@observer
class App extends Component {
    /**
     * @constructor
     * @description App component
     * @param {Object} props Props
     */
    constructor(props) {
        super(props);
        this.AppStore = this.props.AppStore;
    }

    render() {
        return (
            <div className='app'>
                <Suspense
                    fallback={
                        <div style={{ padding: '20px 40px' }}>
                            <Skeleton active />
                        </div>
                    }>
                    {this.AppStore.is_logged_in ? <MainPage /> : <LoginPage />}
                </Suspense>
            </div>
        );
    }
}

export default withRouter(App);
